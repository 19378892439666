import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const Auth = props => {
	
	let { uid } = useSelector(state => state.authUser);
	const history = useHistory();
	if (uid) {
			history.push('/home');
		}
	return (
		<>
			{props.children}
		</>
	);
};

export default Auth;
