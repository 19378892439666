
import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAldD0fba2gPLU667xX7M5PeGwFs_0J0aQ",
  authDomain: "nft-project-f9d31.firebaseapp.com",
  projectId: "nft-project-f9d31",
  storageBucket: "nft-project-f9d31.appspot.com",
  messagingSenderId: "102309211459",
  appId: "1:102309211459:web:563fc30f571c910d8e53f6",
  measurementId: "G-G7R8H49WG5"
};
firebase.initializeApp(firebaseConfig);
export default firebase;
