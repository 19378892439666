import { Col, Row } from "reactstrap";
import Fade from "react-reveal";
const UniqueMottoSection = () => {
  return (
    <>
      <Fade bottom>
        <Row className="uniqueMotto">
          <Col
            xs={{
              offset: 3,
              size: 7,
            }}
          >
            <p className="uniqueMottoDescription">
              EVERY UNIQUE MOTTO IS IN GENERAL PRICELESS AS IT DEFINES WHO YOU ARE BUT FOR THE SILVER EDITION YOU ONLY PAY 0.35 SOLANA (approximately 49,- $) TO DEMONSTRATE THE WORLD WHO YOU ARE.
            </p>
          </Col>
        </Row>
      </Fade>
    </>
  );
};
export default UniqueMottoSection;
