import Ticker from "react-ticker";
import { Row } from "reactstrap";
import dollarsign from "../../assets/img/Home/dollarsign.png";

const PricingBar = () => {
  return (
    <Row className="pricingBar">
      <Ticker>
        {({ index }) => (
          <>
            <p className="pricingBar__text">Pricing</p>
            <img className="pricingBar__img" src={dollarsign} alt=""></img>
          </>
        )}
      </Ticker>
    </Row>
  );
};
export default PricingBar;
