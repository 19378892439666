import { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else setIsVisible(false);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <button
      onClick={() => scrollToTop()}
      type="button"
      className={`btn btn-success btn-floating btn-lg btn-back-to-top ${
        isVisible ? "" : "d-none"
      }`}
      id="btn-back-to-top"
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};
export default BackToTopButton;
