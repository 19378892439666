import {
  APPROVERS_LOADING,
  GET_APPROVERS,
  GET_APPROVERS_ADDRESSES,
  SET_IS_APPROVER,
  UPDATE_APPROVERS_ADDRESSES_LOADING,
  UPDATE_APPROVERS_LOADING,
} from "../types";

const initialState = {
  approversLoading: false,
  updateApproversLoading: false,
  updateApproversAddressesLoading:false,
  approvers: {},
  approversAddresses:{},
  isApprover: false,
};

const approversReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPROVERS_LOADING:
      return {
        ...state,
        approversLoading: action.payload,
      };
    case UPDATE_APPROVERS_LOADING:
      return {
        ...state,
        updateApproversLoading: action.payload,
      };
      case UPDATE_APPROVERS_ADDRESSES_LOADING:
        return {
          ...state,
          updateApproversAddressesLoading: action.payload,
        };
      
    case GET_APPROVERS:
      return {
        ...state,
        approvers: action.payload,
      };
      case GET_APPROVERS_ADDRESSES:
      return {
        ...state,
        approversAddresses: action.payload,
      };
      case SET_IS_APPROVER:
        return {
          ...state,
          isApprover: action.payload,
        };
    default:
      return state;
  }
};

export default approversReducer;
