import { Col, Row } from "reactstrap";
import charityLogo from "../../assets/img/Home/charityLogo.png";
import Fade from "react-reveal";
const CharitySection = () => {
  return (
    <Fade left>
      <Row className="charity">
        <Col
          xs={{
            offset: 0,
            size: 4,
          }}
        >
          <img className="charityLogoImg" src={charityLogo} alt=""></img>
        </Col>

        <Col
          xs={{
            offset: 1,
            size: 6,
          }}
        >
          <Row className="charityHeader__row">
            <Col
              xs={{
                offset: 6,
                size: 6,
              }}
            >
              <p className="charityHeader">Charity</p>
            </Col>
          </Row>
          <Row>
            <p className="charityDescription">
              NOT EVERYONE IS AS LUCKY AS YOU – WE WILL DONATE 5% OF ALL EARNINGS TO A FOUNDATION THAT CARES FOR INCAPABLE PEOPLE! TO SEE HOW MUCH WE COLLECTED FOR CHARTIY PLEASE CHECK THE FOLLOWING WALLET:
              9KHbapc2FHX8YxkS6QCEZs4r7EJt2CMFEo2ErjEFZbGm
            </p>
          </Row>
        </Col>
      </Row>
    </Fade>
  );
};
export default CharitySection;
