export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_IS_ADMIN = "SET_IS_ADMIN";

export const DATA_LOADING = "GET_DATA_LOADING";
export const MINT_LOADING = "MINT_LOADING";
export const GET_NFTS = "GET_NFTS";
export const GET_MINTED_NFTS = "GET_MINTED_NFTS";
export const GET_NFT_COLLECTION_BY_ID = "GET_NFT_COLLECTION_BY_ID";
export const DEPLOY_LOADING = "DEPLOY_LOADING";
export const DELETE_NFT_LOADING="DELETE_NFT_LOADING"

export const APPROVERS_LOADING = "APPROVERS_LOADING";
export const GET_APPROVERS = "GET_APPROVERS";
export const UPDATE_APPROVERS_LOADING = "UPDATE_APPROVERS_LOADING";

export const TELEGRAM_LOADING="TELEGRAM_LOADING"
export const GET_TELEGRAM_CONFIG="GET_TELEGRAM_CONFIG"

export const TWITTER_LOADING="TWITTER_LOADING"
export const GET_TWITTER_CONFIG="GET_TWITTER_CONFIG"

export const TRUSTED_WALLETS_LOADING = "TRUSTED_WALLETS_LOADING";
export const GET_TRUSTED_WALLETS = "GET_TRUSTED_WALLETS";
export const UPDATE_TRUSTED_WALLETS_LOADING = "UPDATE_TRUSTED_WALLETS_LOADING";

export const ADMIN_ADDRESSES_LOADING = "ADMIN_ADDRESSES_LOADING";
export const GET_ADMIN_ADDRESSES = "GET_ADMIN_ADDRESSES";
export const UPDATE_ADMIN_ADDRESSES_LOADING = "UPDATE_ADMIN_ADDRESSES_LOADING";

export const GET_APPROVERS_ADDRESSES="GET_APPROVERS_ADDRESSES"
export const UPDATE_APPROVERS_ADDRESSES_LOADING="UPDATE_APPROVERS_ADDRESSES_LOADING"

export const SET_IS_APPROVER="SET_IS_APPROVER"

export const MOTTOS_LOADING="MOTTOS_LOADING"
export const GET_MOTTOS="GET_MOTTOS"
export const RESPONSE_LOADING="RESPONSE_LOADING"

export const CHANGE_NETWORK="CHANGE_NETWORK"