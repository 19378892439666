import { GET_MOTTOS, MOTTOS_LOADING, RESPONSE_LOADING } from "../types";

const initialState = {
  mottosLoading: false,
  mottos: [],
  responseLoading: false,
};

const mottoReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOTTOS_LOADING:
      return {
        ...state,
        mottosLoading: action.payload,
      };

    case GET_MOTTOS:
      return {
        ...state,
        mottos: action.payload,
      };
    case RESPONSE_LOADING:
      return {
        ...state,
        responseLoading: action.payload,
      };
    default:
      return state;
  }
};

export default mottoReducer;
