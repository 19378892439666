import { Stepper } from "react-form-stepper";
import { Col, Row } from "reactstrap";

const StepsSection = () => {
  return (
    <>
      <Row className="mt-5">
        <Col
          xs={{
            offset: 1,
            size: 5,
          }}
        >
          <p className="specs__header">Steps</p>
        </Col>
      </Row>
      <Stepper
        className="stepper-main mt-5"
        stepClassName="stepClasses"
        styleConfig={{
          size: "3em",
          completedTextColor: "#000000",
          labelFontSize: "2vw",
        }}
        steps={[
          { label: "PRESS GET IT BUTTON!" },
          { label: "SELECT A COLLECTION" },
          { label: "SUBMIT MOTTO FOR APPROVAL" },
          { label: "GET A LINK TO MINT YOUR NFT" },
        ]}
        activeStep={4}
      />
    </>
  );
};
export default StepsSection;
