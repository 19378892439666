import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NavBarComp from "../components/NftCollections/Navbar";
import { ConnectionProvider, WalletProvider, useWallet } from "@solana/wallet-adapter-react";
import { WalletModalProvider, WalletMultiButton, useWalletModal } from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import { LedgerWalletAdapter, PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter } from "@solana/wallet-adapter-wallets";
import { useMemo } from "react";
require('@solana/wallet-adapter-react-ui/styles.css');
const Main = props => {
	let { uid } = useSelector(state => state.authUser);
	let {network} =useSelector(state => state.wallet);
	const history = useHistory();
	// if (!uid) {
	// 	history.push('/');
	// }
	  	// you can use Mainnet, Devnet or Testnet here
		  const solNetwork = network ==="mainnet"? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;
		  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
		  // initialise all the wallets you want to use
		  const wallets = useMemo(
			  () => [
				  new PhantomWalletAdapter(),
				  new SolflareWalletAdapter({ solNetwork }),
				  new TorusWalletAdapter(),
				  new LedgerWalletAdapter(),
			  ],
			  [solNetwork]
		  );
		  console.log(wallets,"Wallets",solNetwork,endpoint);
	return (
    <>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            {props.children}
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
};

export default Main;
