import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { fabric } from "fabric";
import { toast } from "react-toastify";
import {
  BlobUpload,
  checkDuplicate,
  mintLoading,
  mintNftCollection,
} from "../../store/actions/nftsAction";
import LoadingOverlay from "react-loading-overlay";
import { sendMailToApprovers } from "../../store/actions/approversAction";

const EmailApprovalModal = (props) => {
  const dispatch = useDispatch();
  const { approvers, updateApproversLoading, approversLoading } = useSelector(
    (state) => state.approver
  );
  const [mottoData, setMottoData] = useState({
    motto: "",
    email: "",
  });
  console.log("minted NFT", props.mintedNft);
  const [characterLimitError, setCharacterLimitError] = useState("");

  const [functionState, setFunctionState] = useState({
    mintingButton: "Send for Approval",
    mintingButtonDisabled: true,
    mottoSubmit: "Submit",
    mottoSubmitDisabled: false,
  });

  const handleMottoDataChange = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 25 || e.target.name === "email") {
      setMottoData({ ...mottoData, [e.target.name]: e.target.value });
      setCharacterLimitError("");
    } else {
      setCharacterLimitError("Max character limit of 25");
    }
  };

  const handleMottoSubmit = async (e) => {
    e.preventDefault();
    let isDuplicate = await dispatch(
      checkDuplicate(props.mintedNft, mottoData.motto)
    );
    if (!isDuplicate) {
      setFunctionState({
        ...functionState,
        mottoSubmit: "Submitted",
        mottoSubmitDisabled: true,
        mintingButtonDisabled: false,
      });
      var canvas = new fabric.Canvas("minting-canvas");
      document.getElementById("minting-canvas").fabric = canvas;

      var padding =
        parseInt(
          window
            .getComputedStyle(
              document.getElementById("canvas-main-container"),
              null
            )
            .getPropertyValue("padding-right")
            .replace("px", "")
        ) * 2;
      var canvasContainerWidth =
        parseInt(document.getElementById("canvas-main-container").offsetWidth) -
        padding;
      var heightRatio = parseFloat(200 / 471);
      canvas.setWidth(canvasContainerWidth);
      canvas.setHeight(canvasContainerWidth * heightRatio);

      fabric.Image.fromURL(
        props.mintedNft.image,
        function (oImg) {
          oImg.scaleToWidth(canvasContainerWidth);
          oImg.scaleToHeight(canvasContainerWidth * heightRatio);
          canvas.setBackgroundImage(oImg, null, {
            scaleX: canvas.width / oImg.width,
            scaleY: canvas.height / oImg.height,
          });

          console.log("canvasContainerWidth", canvasContainerWidth);
          var text = new fabric.Textbox(mottoData.motto, {
            textAlign: "center",
            top: (canvasContainerWidth * heightRatio) / 2 - 20,
            // left:
            // width: 600,
            width: canvasContainerWidth,
            lineHeight: canvasContainerWidth * heightRatio,
            fontSize: canvasContainerWidth / 10 - 10,
            // fontSize: 40,
            editable: false,
          });

          canvas.add(text);
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } else {
      toast.error("Duplicate not allowed");
      clearFields();
    }
  };

  const clearFields = (e) => {
    setMottoData({
      motto: "",
      email: "",
    });
    setCharacterLimitError("");
    props.handleMintingModal((prevState) => !prevState);
    setFunctionState({
      mintingButton: "Mint",
      mintingButtonDisabled: true,
      mottoSubmit: "Submit",
      mottoSubmitDisabled: false,
    });
  };

  const requestForApproval = () => {
    console.log(approvers.approvers, mottoData, "mottoData");
    const temp = { ...mottoData, collectionId: props.mintedNft.id,status:"pending" };
    dispatch(
      sendMailToApprovers(temp, approvers.approvers, () => {
        toast.success("Email Sent");
        clearFields();
      })
    );
  };

  return (
    <div>
      <Modal
        isOpen={props.mintingModalOpen}
        toggle={() => {
          clearFields();
          props.handleMintingModal((prevState) => !prevState);
        }}
        centered
        style={{ width: "100%", maxWidth: "640px" }}
      >
        <ModalBody className={`${props.homepage ? "homepage-modal" : ""}`}>
          <Form
            onSubmit={(e) => {
              handleMottoSubmit(e);
            }}
          >
            <Row>
              <Col md="12" className="my-2">
                <Label
                  className={`mb-0 my-auto ${
                    props.homepage ? "enter-motto-text" : ""
                  }`}
                >
                  Enter your Motto:
                </Label>
                <Input
                  className="motto-input"
                  name="motto"
                  required
                  value={mottoData.motto}
                  onChange={(e) => {
                    handleMottoDataChange(e);
                  }}
                  invalid={characterLimitError !== ""}
                />
                <FormFeedback>{characterLimitError}</FormFeedback>
              </Col>
              <Col md="12" className="my-2">
                <Label
                  className={`mb-0 my-auto ${
                    props.homepage ? "enter-motto-text" : ""
                  }`}
                >
                  Enter your Email:
                </Label>
                <Input
                  className="motto-input"
                  name="email"
                  required
                  type="email"
                  value={mottoData.email}
                  onChange={(e) => {
                    handleMottoDataChange(e);
                  }}
                />
              </Col>
            </Row>

            <div className="text-right my-2">
              <Button
                type="submit"
                color={`${props.homepage ? "info" : "primary"}`}
                className="px-5 review-btn"
                disabled={functionState.mottoSubmitDisabled}
              >
                {functionState.mottoSubmit}
              </Button>
            </div>
          </Form>
          
          <Alert color="info"><h6 > ⓘ You will recieve an email with a link to mint, after your motto is approved. Average time for approval is around 10 minutes. </h6></Alert>
          <h6 className="text-white">
            Enter Motto and Email for a preview of your NFT
          </h6>
          <Row>
            <Col
              md="12"
              className="align-items-center justify-content-center"
              id="canvas-main-container"
            >
              <canvas height={280} width={600} id="minting-canvas"></canvas>
            </Col>
          </Row>
          <div className="text-right my-2">
            <Button
              type="submit"
              className="px-5 review-btn"
              color={`${props.homepage ? "info" : "primary"}`}
              disabled={
                functionState.mintingButtonDisabled || updateApproversLoading
              }
              onClick={requestForApproval}
            >
              {updateApproversLoading ? (
                <Spinner></Spinner>
              ) : (
                "Send for approval"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmailApprovalModal;
