import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Spinner } from "reactstrap";
import {
  getApprovers,
  getApproversWallets,
  updateApprovers,
  updateApproversAddresses,
} from "../../store/actions/approversAction";

const ApproversList = () => {
  const [emailAddresses, setEmailAddresses] = useState([]); //used to store current and old approvers
  const [approversWalletAddresses, setApproversWalletAddresses] = useState([]); //used to store current and old approvers addresses
  const { approvers,approversAddresses, updateApproversLoading,updateApproversAddressesLoading, approversLoading } = useSelector(
    (state) => state.approver
  );
  console.log(approvers);
  const [newMail, setNewMail] = useState("");
  const [newWallet, setNewWallet] = useState("");
  const dispatch = useDispatch();
  const handleAddEmails = () => {
    setEmailAddresses([...emailAddresses, newMail]);
    setNewMail("");
  };
  const handleRemoveEmail = (ind) => {
    console.log(ind, "INDEXS", emailAddresses);
    let temp = emailAddresses;
    temp.splice(ind, 1);
    setEmailAddresses([...temp]);
  };

  const setValueEmails = (ind, e) => {
    let temp = [...emailAddresses];
    temp[ind] = e.target.value;
    setEmailAddresses(temp);
  };
  const handleAddWallet = () => {
    setApproversWalletAddresses([...approversWalletAddresses, newWallet]);
    setNewWallet("");
  };
  
  const handleRemoveWallet = (ind) => {
    let temp = approversWalletAddresses;
    temp.splice(ind, 1);
    setApproversWalletAddresses([...temp]);
  };
  
  const setValueWallets = (ind, e) => {
    let temp = [...approversWalletAddresses];
    temp[ind] = e.target.value;
    setApproversWalletAddresses(temp);
  };
  useEffect(() => {
    dispatch(getApprovers());
    dispatch(getApproversWallets())
  }, []);
  useEffect(() => {
    if (approvers?.approvers?.length > 0) {
      setEmailAddresses(approvers?.approvers);
    }
  }, [approvers?.approvers]);
  useEffect(() => {
    if (approversAddresses?.approversAddresses?.length > 0) {
      setApproversWalletAddresses(approversAddresses.approversAddresses);
    }
  }, [approversAddresses?.approversAddresses]);
  console.log("Emails", emailAddresses);
  return (
    <>
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(updateApprovers(emailAddresses, approvers.id));
      }}
    >
      <Row>
        <Col md="12 mt-3">
          <h3>Approvers</h3>
        </Col>
        {approversLoading ? (
          <Spinner></Spinner>
        ) : (
          <Col md="10">
            <div className="my-2 ml-3">
              {
                <>
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Button className="addword-icon" onClick={handleAddEmails}>
                      {"Add new email "}
                      <span>
                        <i class="fas fa-plus-circle"></i>
                      </span>{" "}
                    </Button>
                  </Col>
                </>
              }
              {emailAddresses.map((obj, ind) => {
                return (
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Input
                      className=" w-100 mr-2"
                      required
                      type="email"
                      name="emailAddresses"
                      placeholder="Enter Email"
                      value={obj}
                      onChange={(e) => {
                        setValueEmails(ind, e);
                      }}
                    />

                    {
                      <Button
                        className="addword-icon-minus"
                        onClick={() => {
                          handleRemoveEmail(ind);
                        }}
                      >
                        <i
                          className="fa fa-minus-circle"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    }
                  </Col>
                );
              })}
            </div>
          </Col>
        )}
      </Row>
      <Button
        // style={{
        //   background: "#FFBD00",
        //   color: "#1C1C1B",
        //   margin: "3px",
        // }}
        className="ml-3"
        type="submit"
      >
        {updateApproversLoading ? <Spinner size="sm" /> : "Update"}
      </Button>
    </Form>
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(updateApproversAddresses(approversWalletAddresses, approversAddresses.id));
      }}
    >
      <Row>
        <Col md="12 mt-3">
          <h3>Approvers Wallet Addresses</h3>
        </Col>
        {approversLoading ? (
          <Spinner></Spinner>
        ) : (
          <Col md="10">
            <div className="my-2 ml-3">
              {
                <>
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Button className="addword-icon" onClick={handleAddWallet}>
                      {"Add new wallet "}
                      <span>
                        <i class="fas fa-plus-circle"></i>
                      </span>{" "}
                    </Button>
                  </Col>
                </>
              }
              {approversWalletAddresses.map((obj, ind) => {
                return (
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Input
                      className=" w-100 mr-2"
                      required
                      name="approversAddresses"
                      placeholder="Enter Wallet Address"
                      value={obj}
                      onChange={(e) => {
                        setValueWallets(ind, e);
                      }}
                    />

                    {
                      <Button
                        className="addword-icon-minus"
                        onClick={() => {
                          handleRemoveWallet(ind);
                        }}
                      >
                        <i
                          className="fa fa-minus-circle"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    }
                  </Col>
                );
              })}
            </div>
          </Col>
        )}
      </Row>
      <Button
        // style={{
        //   background: "#FFBD00",
        //   color: "#1C1C1B",
        //   margin: "3px",
        // }}
        className="ml-3"
        type="submit"
      >
        {updateApproversAddressesLoading ? <Spinner size="sm" /> : "Update"}
      </Button>
    </Form>
    </>
  );
};

export default ApproversList;
