import {
  APPROVERS_LOADING,
  UPDATE_APPROVERS_LOADING,
  GET_APPROVERS,
  GET_APPROVERS_ADDRESSES,
  UPDATE_APPROVERS_ADDRESSES_LOADING,
  SET_IS_APPROVER,
} from "../types";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";

export const approverLoading = (val) => async (dispatch) => {
  dispatch({
    type: APPROVERS_LOADING,
    payload: val,
  });
};
export const updateLoading = (val) => async (dispatch) => {
  dispatch({
    type: UPDATE_APPROVERS_LOADING,
    payload: val,
  });
};
export const updateApproversAddressesLoading = (val) => async (dispatch) => {
  dispatch({
    type: UPDATE_APPROVERS_ADDRESSES_LOADING,
    payload: val,
  });
};
export const isApprover = (val) => async (dispatch) => {
  dispatch({ type: SET_IS_APPROVER, payload: val });
};
export const getApprovers = (payload) => async (dispatch) => {
  dispatch(approverLoading(true));
  // let { data } = await TerraRepository.getAllUsers();
  await firebase
    .firestore()
    .collection("Approvers")
    .onSnapshot((query) => {
      dispatch(approverLoading(false));
      let tempData = [];
      query.forEach((doc) => {
        tempData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: GET_APPROVERS,
        payload: tempData[0],
      });

      dispatch(approverLoading(false));
    });
};
export const getApproversWallets = (payload) => async (dispatch) => {
  dispatch(approverLoading(true));
  // let { data } = await TerraRepository.getAllUsers();
  await firebase
    .firestore()
    .collection("Approvers_Addresses")
    .onSnapshot((query) => {
      dispatch(approverLoading(false));
      let tempData = [];
      query.forEach((doc) => {
        tempData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: GET_APPROVERS_ADDRESSES,
        payload: tempData[0],
      });

      dispatch(approverLoading(false));
    });
};
export const updateApprovers =
  (approvers, id, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(updateLoading(true));
    firebase
      .firestore()
      .collection("Approvers")
      .doc(id)
      .update({
        approvers,
      })
      .then(() => {
        onSuccess();
        dispatch(updateLoading(false));
        toast.success("Emails updated");
      })
      .catch((e) => {
        onError(e.message);
        dispatch(updateLoading(false));
      });
  };
  export const updateApproversAddresses =
  (approversAddresses, id, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(updateApproversAddressesLoading(true));
    firebase
      .firestore()
      .collection("Approvers_Addresses")
      .doc(id)
      .update({
        approversAddresses,
      })
      .then(() => {
        onSuccess();
        dispatch(updateApproversAddressesLoading(false));
        toast.success("Wallets updated");
      })
      .catch((e) => {
        console.log("Error",e);
        dispatch(updateApproversAddressesLoading(false));
      });
  };
export const sendMailToApprovers =
  (payload, approvers, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateLoading(true));
      var result = await firebase
        .firestore()
        .collection("NftsMottos")
        .add({...payload,createdDate: firebase.firestore.FieldValue.serverTimestamp() });
      console.log(result, "result");
      let obj = {
        motto: payload.motto,
        collectionId:payload.collectionId,
        id: result.id,
        approvers: approvers,
      };
      console.log(obj, "obj");
      const response = await axios.post(
        "https://us-central1-nft-project-f9d31.cloudfunctions.net/sendEmail",
        obj
      );
      console.log(response, "response");
      if (response.status === 200) {
        dispatch(updateLoading(false));
        onSuccess();
      }
    } catch (e) {
      console.log(e);
      dispatch(updateLoading(false));
    }
  };

  export const verifyApprover = (address, onFail = () => {}) => async (dispatch) => {
    dispatch(approverLoading(true));
  
    return firebase
      .firestore()
      .collection("Approvers_Addresses")
      .doc("approversAddresses")
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onFail();
          dispatch(approverLoading(false));
          return false;
        }
       
        const approversAddresses = doc.data().approversAddresses;
        console.log(approversAddresses,"TEST APP");
        const isApproverAddress = approversAddresses.includes(address);
        dispatch(isApprover(isApproverAddress))
        dispatch(approverLoading(false));
        if(!isApproverAddress) onFail();
        return isApproverAddress;
      })
      .catch((e) => {
        console.log(e,"Not approver");
        return false;
      });
  };