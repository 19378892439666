import { Col, Row } from "reactstrap";
import mottoNft from "../../assets/img/Home/mottoNft.png";
import Fade from "react-reveal";
const StartSection = () => {
  return (
    <>
      {/* <div className="mottoNFT">
        <img src={mottoNft} alt="" className="mottoNFT__img"></img>
      </div> */}

      <Col>
        {/* <p className="mottoNFT__brand">Motto Nft</p> */}
      </Col>

      <Fade left>
        <Col className="mottoQuestionText">
          <p className="mottoQuestionText__1">What is</p>
          <p className="mottoQuestionText__2">your motto?</p>
        </Col>
      </Fade>
      <Fade right>
        <Row>
          <Col xs={6}></Col>
          <Col className="mottoDescription" xs={5}>
            <p>
            IMAGINE IT WOULD BE GENUINE FOR YOU – NO ONE ELSE CAN PROVE TO HAVE THE SAME MOTTO. IF YOU GET EXCITED, YOU ARE AT THE RIGHT PLACE. WITH MOTTO-NFT YOU CAN SHOW THE WORLD WHO YOU ARE BY OWNING YOUR MOTTO AS A NFT. LIVE YOUR LIVE WITH YOUR MOTTO. ONE RESERVED MOTTO CAN NEVER BE THE SAME AGAIN! DOES NOT MATTER WHICH COLLECTION WE RELEASE!
            </p>
          </Col>
        </Row>
      </Fade>
      <Row className="startNowText">
        <p className="startNowText__1">Start Now&nbsp;–&nbsp;</p>
        <p className="startNowText__2">Be unique</p>
        {/* <img src={beUnique} alt="Be unique" className="startNowText__2"></img>
    
    <svg viewBox="0 0 450 50">
      <text y="50">Be unique</text>
    </svg> */}
      </Row>
    </>
  );
};
export default StartSection;
