import {
  DATA_LOADING,
  MINT_LOADING,
  GET_NFTS,
  GET_MINTED_NFTS,
  GET_NFT_COLLECTION_BY_ID,
  DEPLOY_LOADING,
  DELETE_NFT_LOADING,
} from "../types";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";

export const getLoader = (val) => async (dispatch) => {
  dispatch({
    type: DATA_LOADING,
    payload: val,
  });
};
export const deployLoader = (val) => async (dispatch) => {
  dispatch({
    type: DEPLOY_LOADING,
    payload: val,
  });
};
export const mintLoading = (val) => async (dispatch) => {
  dispatch({
    type: MINT_LOADING,
    payload: val,
  });
};
export const deleteLoading = (val) => async (dispatch) => {
  dispatch({
    type: DELETE_NFT_LOADING,
    payload: val,
  });
};
export const getNFTCollections = (payload) => async (dispatch) => {
  dispatch(getLoader(true));
  // let { data } = await TerraRepository.getAllUsers();
  await firebase
    .firestore()
    .collection("NftCollection")
    .onSnapshot((query) => {
      dispatch(getLoader(false));
      let tempData = [];
      query.forEach((doc) => {
        tempData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: GET_NFTS,
        payload: tempData,
      });

      dispatch(getLoader(false));
    });
};
export const getNFTCollectionById =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(getLoader(true));
    // let { data } = await TerraRepository.getAllUsers();
    await firebase
      .firestore()
      .collection("NftCollection")
      .doc(id)
      .onSnapshot(async (data) => {
        dispatch({
          type: GET_NFT_COLLECTION_BY_ID,
          payload: {
            id,
            ...data.data(),
          },
        });
        if (onSuccess) {
          onSuccess(data.exists);
        }
      });
  };
export const addNftCollection =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    let imageUrl = payload.imageUrl; // Check if payload.imageUrl exists

    // If payload.imageUrl doesn't exist, upload payload.image to Firebase storage
    if (!imageUrl && payload.image) {
      let fileName1 = payload.image.name;
      let fileName2 = fileName1.slice(fileName1.lastIndexOf("."));
      let fileName3 = uuidv4() + fileName2.toLowerCase();
      let storageRef = await firebase.storage().ref(fileName3).put(payload.image);
      imageUrl = await storageRef.ref.getDownloadURL();
    }
    firebase
      .firestore()
      .collection("NftCollection")
      .doc(payload.collectionName + "-" + payload.collectionShortName)
      .set({
        ...payload,
        image: imageUrl,
        mottos: [],
        paused: false,
      })
      .then(async () => {
        // toast.success("Nft Collections Successfully");
        onSuccess();
        dispatch(deployLoader(false));
      })
      .catch((error) => {
        toast.warning(error);
        dispatch(deployLoader(false));
        console.log(error);
      });
  };
  export const deleteNFTCollection = (id) => async (dispatch) => {
    dispatch(deleteLoading(true));
    const firestore = firebase.firestore(); // Get the Firestore instance from your Firebase instance
  
    const deleteCollectionPromise = new Promise(async (resolve, reject) => {
      try {
        await firestore.runTransaction(async (transaction) => {
          // Delete the NFT collection document
          const collectionRef = firestore.collection("NftCollection").doc(id);
          const collectionDoc = await transaction.get(collectionRef);
  
          if (!collectionDoc.exists) {
            throw new Error("NFT collection does not exist");
          }
  
          transaction.delete(collectionRef);
  
          // Get the minted NFTs related to the collection
          const mintedNftsQuery = await firestore
            .collection("Minted_Nfts")
            .where("nftId", "==", id)
            .get();
  
          mintedNftsQuery.forEach((doc) => {
            transaction.delete(doc.ref);
          });
  
          // Get the mottoes related to the collection
          const mottoesQuery = await firestore
            .collection("NftsMottos")
            .where("collectionId", "==", id)
            .get();
  
          mottoesQuery.forEach((doc) => {
            transaction.delete(doc.ref);
          });
        });
  
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  
    toast.promise(deleteCollectionPromise, {
      pending: 'Deleting collection...',
      success: 'Collection deleted successfully',
      error: 'Error deleting collection',
    });
  
    try {
      await deleteCollectionPromise;
      dispatch(deleteLoading(false));
    } catch (error) {
      console.error('Error deleting NFT collection:', error);
      toast.error(error.message)
      dispatch(deleteLoading(false));
    }
  };
  export const checkNftExists  = (combinedId) => async (dispatch) =>{
    try {
      const collectionRef = firebase
        .firestore()
        .collection("NftCollection")
        .doc(combinedId);
  
      const collectionDoc = await collectionRef.get();
  
      if (!collectionDoc.exists) {
        console.error("Collection does not exist");
        return false;
      }
      return true
    } catch (error) {
      console.error("Error checking NFT existence:", error);
      return false;
    }
  };
export const mintNftCollection =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("test action");
    dispatch(mintLoading(true));
    let jsonTestObj = {
      name: `${payload.collectionShortName}-${payload.totalSupply}`,
      description: `${payload.collectionName}`,
      // image: `https://picsum.photos/id/${payload.totalSupply}/200/200`,
      image: payload.mintedImage,
    };
    // convert your object into a JSON-string
    let jsonString = JSON.stringify(jsonTestObj);
    // create a Blob from the JSON-string
    let blob = new Blob([jsonString], { type: "application/json" });
    let storageRef = await firebase
      .storage()
      .ref(`Collections/${payload.id}/${payload.totalSupply}.json`)
      .put(blob);
    let url = await storageRef.ref.getDownloadURL();
    console.log("URL", url);
    firebase
      .firestore()
      .collection("Minted_Nfts")
      .doc(
        payload.collectionName +
          "-" +
          payload.collectionShortName +
          `${payload.totalSupply}`
      )
      .set({
        nftId: payload.id,
        metaDataUrl: url,
        ...jsonTestObj,
      })
      .then(async () => {
        var unsafeMotto = payload.motto;
        var trimedMotto = unsafeMotto.replace(/\s+/g, "");
        firebase
          .firestore()
          .collection("NftCollection")
          .doc(payload.id)
          .update({
            mottos: firebase.firestore.FieldValue.arrayUnion(trimedMotto),
            mintCount:firebase.firestore.FieldValue.increment(1)
          })
          .then(() => {
            // toast.success("NFT Minted Successfully");
            onSuccess();
            dispatch(mintLoading(false));
          });
      })
      .catch((error) => {
        toast.warning(error);
        dispatch(mintLoading(false));
        console.log(error);
      });
  };

export const getMintedNftsByCollection = (id) => async (dispatch) => {
  dispatch(getLoader(true));
  firebase
    .firestore()
    .collection("Minted_Nfts")
    .where("nftId", "==", id)
    .onSnapshot(async (data) => {
      let tempData = [];
      for (let doc of data.docs) {
        let id = doc.id;
        let data1 = doc.data();
        tempData.push({ id: id, ...data1 });
      }
      dispatch({ type: "GET_MINTED_NFTS", payload: tempData });
      dispatch(getLoader(false));
    });
};

export const updateNftCollection =
  (payload, nftId, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("NftCollection")
      .doc(nftId)
      .update({
        ...payload,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e.message);
      });
  };
export const updateNftMottos =
  (motto, nftId, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    var trimedMotto = motto.replace(/\s+/g, "");
    firebase
      .firestore()
      .collection("NftCollection")
      .doc(nftId)
      .update({
        mottos: firebase.firestore.FieldValue.arrayUnion(trimedMotto),
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e.message);
      });
  };
export const BlobUpload =
  (payload, blob, onSuccess = () => {}) =>
  async (dispatch) => {
    // let fileName1 = payload.image.name;
    // let fileName2 = fileName1.slice(fileName1.lastIndexOf("."));
    // let fileName3 = uuidv4() + fileName2.toLowerCase();

    let storageRef = await firebase
      .storage()
      .ref(`Collections/${payload.id}/${parseInt(payload.totalSupply)}.png`)
      .put(blob);
    let imageUrl = await storageRef.ref.getDownloadURL();
    return imageUrl;
  };

export const checkDuplicate = (payload, motto) => async (dispatch) => {
  // let fileName1 = payload.image.name;
  // let fileName2 = fileName1.slice(fileName1.lastIndexOf("."));
  // let fileName3 = uuidv4() + fileName2.toLowerCase();
  let docData = await firebase
    .firestore()
    .collection("NftCollection")
    .doc(payload.id)
    .get();
  const data = docData.data();
  var trimmedInputMotto = motto.replace(/\s+/g, "");

  if (data.mottos.includes(trimmedInputMotto) && payload.duplicate !== true)
    return true;
  return false;
};

export const uploadCollectionMetadeta =
  (nftData,combinedId, onSuccess = () => {}) =>
  async (dispatch) => {
    try {

      // Upload the image to Firebase Storage
      const imageStorageRef = firebase.storage().ref(`Collections/${combinedId}/main.png`);
      await imageStorageRef.put(nftData.image);
      const imageUrl = await imageStorageRef.getDownloadURL();

      // Prepare metadata for main.json
      const metadata = {
        name: nftData.collectionName,
        description: `${nftData.collectionName} ${nftData.collectionShortName}`,
        img: imageUrl
      };

      // Convert metadata to JSON string
      const metadataString = JSON.stringify(metadata);
       // create a Blob from the JSON-string
      let blob = new Blob([metadataString], { type: "application/json" });
      // Upload main.json to Firebase Storage
      let storageRef = await firebase
      .storage()
      .ref(`Collections/${combinedId}/main.json`)
      .put(blob);
       let url = await storageRef.ref.getDownloadURL();
       const obj={
        mainMetadataUrl:url,
        imageUrl
       }
       return obj
      // const jsonFileName = 'main.json';
      // const jsonStorageRef = firebase.storage().ref(`Collections/${combinedId}/${jsonFileName}`);
      // await jsonStorageRef.putString(metadataString);

      // Retrieve the download URL of main.json
      // const jsonUrl = await jsonStorageRef.getDownloadURL();
      // return jsonUrl

    } catch (error) {
      // Display error toast message
      toast.error("Error: " + error.message);
      // Log error to the console
      console.error(error);
    }
  };
