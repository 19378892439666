import {
    TRUSTED_WALLETS_LOADING,
    GET_TRUSTED_WALLETS,
    UPDATE_TRUSTED_WALLETS_LOADING,
    CHANGE_NETWORK,
  } from "../types";
  
  const initialState = {
    trustedWalletsLoading: false,
    updateTrustedWalletsLoading: false,
    trustedWallets: {},
    network:"mainnet"
  };
  
  const walletsReducer = (state = initialState, action) => {
    switch (action.type) {
      case TRUSTED_WALLETS_LOADING:
        return {
          ...state,
          trustedWalletsLoading: action.payload,
        };
      case UPDATE_TRUSTED_WALLETS_LOADING:
        return {
          ...state,
          updateTrustedWalletsLoading: action.payload,
        };
      case GET_TRUSTED_WALLETS:
        return {
          ...state,
          trustedWallets: action.payload,
        };
        case CHANGE_NETWORK:
          return {
            ...state,
            network: action.payload,
          };
  
      default:
        return state;
    }
  };
  
  export default walletsReducer;
  