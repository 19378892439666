import { Button, Container, Row, Col } from "reactstrap";
import "./Home.css";
import NftAccordions from "../components/Home/NftAccordions";
import StartSection from "../components/Home/StartSection";
import SilverMottoSection from "../components/Home/SilverMottoSection";
import PricingBar from "../components/Home/PricingBar";
import UniqueMottoSection from "../components/Home/UniqueMottoSection";
import CharitySection from "../components/Home/CharitySection";
import FaqBar from "../components/Home/FaqBar";
import SpecsSection from "../components/Home/SpecsSection";
import Fallback from "../components/Home/Fallback";
// import Navbar from "../components/Home/Navbar";
import { useEffect, useMemo, useRef, useState } from "react";
import BackToTopButton from "../components/Home/BackToTopButton";
import Fade from "react-reveal";
import { toast } from "react-toastify";
import HomeCollectionsModal from "../components/Home/HomeCollectionsModal";
import { getNFTCollections } from "../store/actions/nftsAction";
import { useDispatch, useSelector } from "react-redux";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3EthContract from "web3-eth-contract";
// import WalletConnect from "@walletconnect/web3-provider";
import LoadingOverlay from "react-loading-overlay";
import { Stepper } from "react-form-stepper";
import StepsSection from "../components/Home/StepsSection";
import { getApprovers } from "../store/actions/approversAction";


import ConnectButton from "../components/Home/ConnectButton";
import TempTransferTest from "./TempTransferTest";
import TelegramBanner from "../components/Home/TelegramBanner";


// export const providerOptions = {
//   walletconnect: {
//     package: WalletConnect, // required
//     options: {
//       infuraId: "e89125efc4c14b968e96ee98434ef68e", // required
//     },
//   },
// };
// const web3Modal = new Web3Modal({
//   cacheProvider: true, // optional
//   providerOptions, // required
// });
const Home = () => {
  // const testprovider = new WalletConnectProvider({
  //   infuraId: "e89125efc4c14b968e96ee98434ef68e",
  // });
  const { mintLoader } = useSelector((state) => state.nft);
  const dispatch = useDispatch();
  let [nftModalOpen, setToggleModal] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(
    window?.ethereum?._state?.accounts?.length > 0 ? true : false
  );
  const mottoRef = useRef(null);
  const charityRef = useRef(null);
  const specsRef = useRef(null);
  const stepsRef = useRef(null);
  const faqRef = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    dispatch(getNFTCollections());
    dispatch(getApprovers());
  }, []);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    // Check if the user has scrolled down more than a specific threshold (e.g. 10px)
    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   window.onload = (event) => {
  //     isConnected();
  //   };

  //   async function isConnected() {
  //     const accounts = await window?.ethereum.request({
  //       method: "eth_accounts",
  //     });
  //     if (accounts.length) {
  //       console.log(`You're connected to: ${accounts[0]}`);
  //       setIsWalletConnected(true);
  //     } else {
  //       console.log("Metamask is not connected");
  //       setIsWalletConnected(false);
  //     }
  //   }

  //   if (window.ethereum) {
  //     window.ethereum.on("chainChanged", () => {
  //       console.log("Test chainchanged");
  //     });
  //     window.ethereum.on("accountsChanged", () => {
  //       setIsWalletConnected(
  //         window?.ethereum?._state?.accounts?.length > 0 ? true : false
  //       );
  //       console.log("Test account change");
  //     });
  //   } else {
  //     // connectMetaMask();
  //   }
  // }, []);




  //Have to use refs in a single component file, else react.forward ref is required which can only pass one ref at a time
  return (
    <>
          <TelegramBanner />
        <Container fluid className="background-main">
      <BackToTopButton />

      <div className={`d-flex flex-row align-items-center justify-content-between flex-wrap  pl-4 pr-4 ${isScrolled ? 'glassdoor-background fixed-top' : 'fixed-top-offset'}`}>
        <p className="mottoNFT__brand">Motto Nft</p>
        <Row className="navbarRow">
          {/* <Col>
          <p className="mottoNFT__brand">Motto Nft</p>
        </Col> */}
          <Button
            onClick={() => {
              setToggleModal(!nftModalOpen);
              // scrollToSection(mottoRef);
            }}
            className="navbar__button"
          >
            GET IT!
          </Button>
          <Button
            onClick={() => {
              // setToggleModal(!nftModalOpen);
              scrollToSection(charityRef);
            }}
            className="navbar__button"
          >
            CHARITY
          </Button>
          <Button
            onClick={() => scrollToSection(specsRef)}
            className="navbar__button"
          >
            SPECS
          </Button>
          <Button
            onClick={() => scrollToSection(stepsRef)}
            className="navbar__button"
          >
            STEPS
          </Button>
          {/* <Button
          onClick={() => {
            linkWallet();
          }}
          className="navbar__button"
        >
          {isWalletConnected ? (
            <>
              CONNECTED
              <i class="fas fa-check"></i>
            </>
          ) : (
            "CONNECT"
          )}
        </Button> */}
          {/* <WalletMultiButton className="navbar__button" /> */}
          <ConnectButton isWalletConnected={isWalletConnected} />
        </Row>
      </div>
      <Fade>
        <StartSection />
      </Fade>
      <div ref={mottoRef}></div>
      <SilverMottoSection />
      <PricingBar />
      <UniqueMottoSection />
      <div ref={charityRef}></div>
      <CharitySection />

      <FaqBar />
      <NftAccordions />
      <div ref={specsRef}></div>
      <SpecsSection />
      <div ref={stepsRef}></div>
      <StepsSection />
      <div ref={faqRef}></div>
      {/* <Fallback linkWallet={linkWallet} /> */}
      <HomeCollectionsModal
        nftModalOpen={nftModalOpen}
        setToggleModal={setToggleModal}
      />
      {/* <TempTransferTest/>  */}
      <footer className="footer">
        <p>
          Please follow us on <a href="https://t.me/MOTTOSOL" target="_blank" rel="noopener noreferrer">Telegram</a>.
        </p>
        <p>© 2024 MOTTO NFT. All rights reserved.</p>
      </footer>
    </Container>



    </>

  );
};
export default Home;
