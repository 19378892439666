import { useState } from "react";
import { Col, Collapse, Row } from "reactstrap";

const NftAccordions = () => {
  const [openAccordians, setOpenAccordians] = useState([]);
  const toggle = (e) => {
    let temp = [...openAccordians];
    console.log(
      temp.find((val) => val === e),
      "Test"
    );
    if (temp.find((val) => val === e)) {
      temp = temp.filter((val) => val !== e);
      setOpenAccordians(temp);
    } else {
      temp.push(e);
      setOpenAccordians(temp);
    }
  };
  return (
    <>
      <Row className="Accordians">
        <Col
          onClick={() => toggle(1)}
          xs={{
            offset: 1,
            size: 10,
          }}
          className="Accordian__content"
        >
          <p>What is an NFT?{openAccordians.includes(1) ? " ↓" : " →"}</p>
          <Collapse isOpen={openAccordians.includes(1)}>
            <br />
            <div className="Accordian__content__open">
              NFT stands for "non-fungible token". It is a special type of token
              minted on the Solana blockchain. Each token is unique and can
              not be replaced by any other token. Lately, NFT's have taken the
              world by storm, as more and more people use them to collect and
              trade their favorite digital art. Think about them as digital
              collectibles, whose uniqueness and authenticity is verified by the
              blockchain.
            </div>
            <br />
            <br />
          </Collapse>
        </Col>
        <Col
          onClick={() => toggle(2)}
          xs={{
            offset: 1,
            size: 10,
          }}
          className="Accordian__content"
        >
          <p>
            How can I buy a motto-nft?
            {openAccordians.includes(2) ? " ↓" : " →"}
          </p>
          <Collapse isOpen={openAccordians.includes(2)}>
            <br />
            <div className="Accordian__content__open">
              TO BUY A SILVER MOTTO, YOU NEED TO HAVA A SOLANA WALLET SETUP, A COMMONLY RECOMMENDED ONE IS PHANTOM. GET THE PHANTOM CHROME EXTENSION, SET UP YOUR WALLET AND FUND IT WITH SOLANA. ONCE YOU DO THAT, YOU CAN USE THE "CONNECT" BUTTON AT THE TOP OF THE PAGE TO CONNECT YOUR WALLET TO THE SITE. THEN MINT TO YOUR HEART`S CONENT!
            </div>
            <br /> <br />
          </Collapse>
        </Col>
        <Col
          onClick={() => toggle(3)}
          xs={{
            offset: 1,
            size: 10,
          }}
          className="Accordian__content"
        >
          <p>
            How can I see my motto-nft?
            {openAccordians.includes(3) ? " ↓" : " →"}
          </p>
          <Collapse isOpen={openAccordians.includes(3)}>
            <br />
            <div className="Accordian__content__open">
            AFTER YOU USE THE SITE TO MINT AND ONCE THE TRANSACTION IS CONFIRMED, YOUR NEWLY ADOPTED MOTTO WILL SHOW UP IN YOUR WALLET. THEY WILL BE VIEWABLE/TRADEABLE ON ALL MAJOR PLATFORMS, THAT SUPPORT SOLANA.
            </div>
            <br /> <br />
          </Collapse>
        </Col>
      </Row>
    </>
  );
};
export default NftAccordions;
