import { GET_ADMIN_ADDRESSES, SET_IS_ADMIN, ADMIN_ADDRESSES_LOADING, UPDATE_ADMIN_ADDRESSES_LOADING } from "../types";

const initState = {
  isAdmin: false,
  adminAddressesLoading: false,
  updateAdminAddressesLoading: false,
  adminAddresses: {},
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
      case ADMIN_ADDRESSES_LOADING:
        return {
          ...state,
          adminAddressesLoading: action.payload,
        };
      case UPDATE_ADMIN_ADDRESSES_LOADING:
        return {
          ...state,
          updateAdminAddressesLoading: action.payload,
        };
      case GET_ADMIN_ADDRESSES:
        return {
          ...state,
          adminAddresses: action.payload,
        };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
