import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { fabric } from "fabric";
import { toast } from "react-toastify";
import {
  BlobUpload,
  checkDuplicate,
  mintLoading,
  mintNftCollection,
} from "../../store/actions/nftsAction";
import LoadingOverlay from "react-loading-overlay";
import { fetchCandyGuard, fetchCandyMachine, mintV2 } from "@metaplex-foundation/mpl-candy-machine";
import { generateSigner, some, transactionBuilder } from "@metaplex-foundation/umi";
import { setComputeUnitLimit } from "@metaplex-foundation/mpl-toolbox";
import { base58 } from "@metaplex-foundation/umi/serializers";
export const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "e89125efc4c14b968e96ee98434ef68e", // required
    },
  },
};
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});
const MintingModal = (props) => {
  /// TODO: Duplicate Text, motto limit of 20 characters

  const dispatch = useDispatch();
  const [mottoData, setMottoData] = useState({
    motto: "",
  });
  const [mintedNft, setMintedNft] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [characterLimitError, setCharacterLimitError] = useState("");

  const [functionState, setFunctionState] = useState({
    mintingButton: "Mint",
    mintingButtonDisabled: true,
    mottoSubmit: "Submit",
    mottoSubmitDisabled: false,
  });

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     var canvas = new fabric.Canvas("minting-canvas");
  //     document.getElementById("minting-canvas").fabric = canvas;
  //     var padding = parseInt(window.getComputedStyle(document.getElementById("canvas-main-container"),null).getPropertyValue('padding-right').replace('px','')) * 2
  //     var canvasContainerWidth = parseInt(document.getElementById("canvas-main-container").offsetWidth) - padding
  //     console.log("WIDTH: ",canvasContainerWidth)
  //     canvas.setWidth(canvasContainerWidth);
  //     canvas.renderAll();
  //   },500)
  // },[props.mintingModalOpen])

  const handleMottoDataChange = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 25) {
      setMottoData({ ...mottoData, [e.target.name]: e.target.value });
      setCharacterLimitError("");
    } else {
      setCharacterLimitError("Max character limit of 25");
    }
  };

  const handleMottoSubmit = async (e) => {
    e.preventDefault();
    let isDuplicate = await dispatch(
      checkDuplicate(props.mintedNft, mottoData.motto)
    );
    if (!isDuplicate) {
      setFunctionState({
        ...functionState,
        mottoSubmit: "Submitted",
        mottoSubmitDisabled: true,
        mintingButtonDisabled: false,
      });
      var canvas = new fabric.Canvas("minting-canvas");
      document.getElementById("minting-canvas").fabric = canvas;

      var padding =
        parseInt(
          window
            .getComputedStyle(
              document.getElementById("canvas-main-container"),
              null
            )
            .getPropertyValue("padding-right")
            .replace("px", "")
        ) * 2;
      var canvasContainerWidth =
        parseInt(document.getElementById("canvas-main-container").offsetWidth) -
        padding;
      var heightRatio = parseFloat(200 / 471);
      canvas.setWidth(canvasContainerWidth);
      canvas.setHeight(canvasContainerWidth * heightRatio);

      fabric.Image.fromURL(
        // "https://firebasestorage.googleapis.com/v0/b/nft-project-f9d31.appspot.com/o/b0848ab6-205c-41d2-8a92-01d6e37135e5.png?alt=media",
        props.mintedNft.image,
        function (oImg) {
          // oImg.scaleToWidth(600);
          // oImg.scaleToHeight(280);
          oImg.scaleToWidth(canvasContainerWidth);
          oImg.scaleToHeight(canvasContainerWidth * heightRatio);
          canvas.setBackgroundImage(oImg, null, {
            scaleX: canvas.width / oImg.width,
            scaleY: canvas.height / oImg.height,
          });

          // var padding = parseInt(window.getComputedStyle(document.getElementById("canvas-main-container"),null).getPropertyValue('padding-right').replace('px','')) * 2
          // var canvasContainerWidth = parseInt(document.getElementById("canvas-main-container").offsetWidth) - padding
          // var heightRatio = parseFloat(280/600)
          console.log("canvasContainerWidth", canvasContainerWidth);
          var text = new fabric.Textbox(mottoData.motto, {
            textAlign: "center",
            top: (canvasContainerWidth * heightRatio) / 2 - 20,
            // left:
            // width: 600,
            width: canvasContainerWidth,
            lineHeight: canvasContainerWidth * heightRatio,
            fontSize: canvasContainerWidth / 10 - 10,
            // fontSize: 40,
            editable: false,
          });
          // canvas.setWidth(canvasContainerWidth);

          // canvas.setHeight(canvasContainerWidth*heightRatio)
          canvas.add(text);
          canvas.renderAll();

          // var imgData = canvas.toDataURL({ format: "png" });

          // var link = document.createElement("a");
          // var blob = dataURLtoBlob(imgData);

          // var objurl = URL.createObjectURL(blob);
          // link.download = "helloWorld.png";

          // link.href = objurl;
          // console.log("link", link.href);

          // link.click();
        },
        { crossOrigin: "anonymous" }
      );
    } else {
      toast.error("Duplicate not allowed");
      clearFields();
    }
  };

  const clearFields = (e) => {
    setMottoData({
      motto: "",
    });
    setCharacterLimitError("");
    props.handleMintingModal((prevState) => !prevState);
    setFunctionState({
      mintingButton: "Mint",
      mintingButtonDisabled: true,
      mottoSubmit: "Submit",
      mottoSubmitDisabled: false,
    });
  };

  const handleCompleteMinting = async (e) => {
    setFunctionState({
      ...functionState,
      mintingButton: "Minting...",
      mintingButtonDisabled: true,
    });
    var link = document.createElement("a");
    // var canvas = new fabric.Canvas("minting-canvas");
    var canvas = document.getElementById("minting-canvas").fabric;

    var padding =
      parseInt(
        window
          .getComputedStyle(
            document.getElementById("canvas-main-container"),
            null
          )
          .getPropertyValue("padding-right")
          .replace("px", "")
      ) * 2;
    var canvasContainerWidth =
      parseInt(document.getElementById("canvas-main-container").offsetWidth) -
      padding;
    var multiplier = parseFloat(600 / canvasContainerWidth);

    // canvas.renderCanvas();
    var imgData = canvas.toDataURL({ format: "png", multiplier: multiplier });

    var blob = dataURLtoBlob(imgData);
    let recieverAccount;


    const candyMachine = await fetchCandyMachine(props.umi, props.mintedNft.candyMachineId)
    const candyGuard = await fetchCandyGuard(props.umi, candyMachine.mintAuthority)
    const totalSupplyCollection=Number(candyMachine.data.itemsAvailable)
    console.log(candyMachine,"candyMachine");
    console.log(candyGuard,"Candy Guard");
    console.log(totalSupplyCollection, "total supply before");
    console.log("props.mintedNft",props.mintedNft);
    if (totalSupplyCollection === props.mintedNft.maxSupply) {
      toast.error("Max Supply Exceeded");
    } else if (props.mintedNft.paused) {
      toast.error("Contract Paused");
    } else {

      // const nftOwner = await generateSigner(umi).publicKey; 

      // var isDuplicate = await dispatch(
      //   checkDuplicate(props.mintedNft, mottoData.motto)
      // );
      // console.log(isDuplicate, "duplicate response");

      dispatch(mintLoading(true));
      setShowWarning(true);
      const mintingPromise = new Promise(async (resolve, reject) => {
        try{
          const nftMint =  generateSigner(props.umi)  
        const transaction = await transactionBuilder()
            .add(setComputeUnitLimit(props.umi, { units: 800_000 }))
            .add(
              mintV2(props.umi, {
                candyMachine: candyMachine.publicKey,
                candyGuard: candyGuard.publicKey,
                nftMint,
                collectionMint:props.mintedNft.collectionMintId,
                collectionUpdateAuthority:props.mintedNft.collectionUpdateAuthorityId,
                  mintArgs: {
                    mintLimit: some({ id: 1 }),
                    solPayment: some({destination: candyMachine.authority }),
                },
              })
            )
            // .sendAndConfirm(props.umi);
           
            const { signature } = await transaction.sendAndConfirm(props.umi, {
              confirm: { commitment: "confirmed" },
          });
          resolve();
          toast("Mint 1 Success")
            const firebaseUpload = new Promise(async (resolve, reject) => {
              try {
                const blobResponse = await dispatch(
                  BlobUpload({ ...props.mintedNft, totalSupply: Number(candyMachine.itemsRedeemed)+1 }, blob)
                );
                console.log(blobResponse, "blobResponse");
                const tempObj = {
                  ...props.mintedNft,
                  motto: mottoData.motto,
                  totalSupply: parseInt(Number(candyMachine.itemsRedeemed)+1),
                  mintedImage: blobResponse,
                };
                dispatch(
                  mintNftCollection(tempObj, () => {
                    // toast.success("Minting Request Sent");
                    resolve();
                    console.log("Minting Completed");
                    toast.success("Mint Success")
                    setShowWarning(false);
                    clearFields();
                  })
                );
              } catch (e) {
                console.log(e);
                reject();
              }
            });

            toast.promise(firebaseUpload, {
              pending: "Creating and Uploading Image",
              success: "Image Uploaded",
              error: "Upload Error",
            });
        }
        catch(error){
          toast.error(error.message)
          console.log(error);
          reject()
        }
        
            
      });

      toast.promise(mintingPromise, {
        pending: "Minting",
        // success: "Promise resolved 👌",
        error: "Minting error",
      });
    }

    // var objurl = URL.createObjectURL(blob);
    // link.download = "helloWorld.png";

    // link.href = objurl;
    // console.log("link", link.href);

    // link.click();
  };

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      if (showWarning) {
        event.preventDefault();
        event.returnValue = "Are you sure? Minting will not be completed!";
      }
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        if (showWarning) {
          event.preventDefault();
          event.returnValue = "Are you sure? Minting will not be completed!";
        }
      });
    };
  }, [showWarning]);
  return (
    <div>
      <Modal
        isOpen={props.mintingModalOpen}
        toggle={() => {
          clearFields();
          props.handleMintingModal((prevState) => !prevState);
        }}
        // modalClassName={`${props.homepage ? "homepage-modal" : ""}`}
        // size= "lg"
        centered
        style={{ width: "100%", maxWidth: "640px" }}
      >
        <ModalBody
          className={`${props.homepage ? "homepage-modal" : ""}`}
          // className="add-nft-model"
        >
          <Form
            onSubmit={(e) => {
              handleMottoSubmit(e);
            }}
          >
            <Row>
              <Col md="12" className="my-2">
                <Label
                  className={`mb-0 my-auto ${
                    props.homepage ? "enter-motto-text" : ""
                  }`}
                >
                  Enter your Motto:
                </Label>
                <Input
                  className="motto-input"
                  name="motto"
                  required
                  value={mottoData.motto}
                  onChange={(e) => {
                    handleMottoDataChange(e);
                  }}
                  invalid={characterLimitError !== ""}
                />
                <FormFeedback>{characterLimitError}</FormFeedback>
              </Col>
            </Row>

            <div className="text-right my-2">
              <Button
                type="submit"
                color={`${props.homepage ? "info" : "primary"}`}
                className="px-5 review-btn"
                disabled={functionState.mottoSubmitDisabled}
              >
                {functionState.mottoSubmit}
              </Button>
            </div>
          </Form>
          <Row>
            <Col
              md="12"
              className="align-items-center justify-content-center"
              id="canvas-main-container"
            >
              <canvas height={280} width={600} id="minting-canvas"></canvas>
            </Col>
          </Row>
          <div className="text-right my-2">
            <Button
              type="submit"
              className="px-5 review-btn"
              color={`${props.homepage ? "info" : "primary"}`}
              disabled={functionState.mintingButtonDisabled}
              onClick={handleCompleteMinting}
            >
              {functionState.mintingButton}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MintingModal;
