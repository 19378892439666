import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Web3EthContract from "web3-eth-contract";
import { abi2 } from "../../config/contractConfig";
import { updateNftCollection } from "../../store/actions/nftsAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
const EditNftModal = (props) => {
  const dispatch = useDispatch();
  const [nftData, setNftData] = useState({});
  const [costLoading, setCostLoading] = useState(false);
  const [supplyLoading, setSupplyLoading] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [trustedLoading, setTrustedLoading] = useState(false);
  const handleNftDataChange = (e) => {
    if (e.target.name === "paused" || e.target.name === "trusted") {
      setNftData({ ...nftData, [e.target.name]: e.target.checked });
    } else {
      e.preventDefault();
      setNftData({ ...nftData, [e.target.name]: e.target.value });
    }
  };

  const clearFields = () => {
    setNftData({
      price: "",
      maxSupply: "",
    });
  };
  useEffect(() => {
    setNftData({
      price: props.editedNft?.price,
      maxSupply: props.editedNft?.maxSupply,
      paused: props.editedNft?.paused,
      trusted:props.editedNft?.trusted
    });
  }, [
    props.editedNft?.price,
    props.editedNft?.maxSupply,
    props.editedNft?.paused,
    props.editedNft?.trusted
  ]);
  const handleUpdate = async (e, type) => {
    e.preventDefault();
    let updateMsg = "";
    let tempObj = {};
    // Web3EthContract.setProvider(window.ethereum);
    // let SmartContractObject = new Web3EthContract(
    //   abi2,
    //   props.editedNft.contractAddress
    // );

    // let accounts = await window.ethereum.request({
    //   method: "eth_requestAccounts",
    // });
    // let recieverAccount = accounts[0];
    if (type === "cost") {
      setCostLoading(true);
      updateMsg = "Cost Updated Sucessfully";
      tempObj.price = nftData.price;
      // let cost = await SmartContractObject.methods.getCost().call();
    } else if (type === "totalSupply") {
      setSupplyLoading(true);
      updateMsg = "Supply Updated Sucessfully";
      tempObj.maxSupply = nftData.maxSupply;
    } else if (type === "pause") {
      setPauseLoading(true);
      updateMsg = `Contract ${
        nftData.paused ? "Paused" : "Unpaused"
      } Successfully`;
      tempObj.paused = nftData.paused;
    }else if (type === "trusted") {
      setTrustedLoading(true);
      updateMsg = `Collection open for ${
        nftData.trusted ? "trusted wallets only" : "all"
      }`;
      tempObj.trusted = nftData.trusted;

          dispatch(
            updateNftCollection(
              tempObj,
              props.editedNft?.id,
              () => {
                setTrustedLoading(false);
                toast.success(updateMsg);
              },
              (e) => {
                setTrustedLoading(false);
                toast.error(e);
              }
            )
          );

    }
  };

  // const handleTotalSupply = async (e) => {
  //   e.preventDefault();
  //   Web3EthContract.setProvider(window.ethereum);
  //   let SmartContractObject = new Web3EthContract(
  //     abi2,
  //     props.editedNft.contractAddress
  //   );

  //   let accounts = await window.ethereum.request({
  //     method: "eth_requestAccounts",
  //   });
  //   let recieverAccount = accounts[0];
  //   let newSupply = await SmartContractObject.methods
  //     .setMaxSupply(nftData.maxSupply)
  //     .send({
  //       to: props.editedNft?.contractAddress,
  //       from: recieverAccount,
  //       // value: ,
  //     });
  //   let totalSupply = await SmartContractObject.methods.totalSupply().call();
  //   console.log("test", totalSupply, newSupply);
  // };

  return (
    <div>
      <Modal
        isOpen={props.nftModalOpen}
        toggle={() => {
          // clearFields();
          props.setToggleModal((prevState) => !prevState);
        }}
        className=""
      >
        <ModalHeader
          className="text-site-dark border-0"
          toggle={() => {
            clearFields();
            props.setToggleModal((prevState) => !prevState);
          }}
        >
          Edit Nft Collection <br /> <h5>{props.editedNft?.collectionName}</h5>
        </ModalHeader>

        <ModalBody className="Edit-nft-model">
          <Form>
            <Row>
              <Col md="7" className="mb-2">
                <Label className="mb-0 my-auto">Price</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="price"
                  required
                  value={nftData.price}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col>
              <Col md="7" className="my-2">
                <Button
                  onClick={(e) => {
                    handleUpdate(e, "cost");
                  }}
                  type="submit"
                  className="px-5"
                  disabled={true}
                >
                  {costLoading ? <Spinner size="sm" /> : "Update Price"}
                </Button>
              </Col>
              {/* <Col md="5"></Col> */}
              <Col md="7" className="my-2">
                <Label className="mb-0 my-auto">Max Supply</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="maxSupply"
                  required
                  value={nftData.maxSupply}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col>
              <Col md="7" className="my-2">
                <Button
                  onClick={(e) => {
                    handleUpdate(e, "totalSupply");
                  }}
                  type="submit"
                  className="px-5"
                  disabled={true}
                >
                  {supplyLoading ? <Spinner size="sm" /> : "Update Max Supply"}
                </Button>
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto mr-4">Pause Collection</Label>
                <Input
                  name="paused"
                  onChange={(e) => {
                    handleNftDataChange(e);
                    console.log(e.target.checked, "$$$", e.target.name);
                  }}
                  defaultChecked={nftData?.paused}
                  type="checkbox"
                />
              </Col>{" "}
              <Col md="7" className="my-2">
                <Button
                  onClick={(e) => {
                    handleUpdate(e, "pause");
                  }}
                  type="submit"
                  className="px-5"
                  disabled
                >
                  {pauseLoading ? <Spinner size="sm" /> : "Update"}
                </Button>
              </Col>
              <Col md="12" className="my-2">
                <Label className="mb-0 my-auto mr-4">Collection for trusted wallets only</Label>
                <Input
                  name="trusted"
                  className="mt-2"
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                  defaultChecked={nftData?.trusted}
                  type="checkbox"
                />
              </Col>{" "}
              <Col md="7" className="my-2">
                <Button
                  onClick={(e) => {
                    handleUpdate(e, "trusted");
                  }}
                  type="submit"
                  className="px-5"
                  disabled={false}
                >
                  {trustedLoading ? <Spinner size="sm" /> : "Update Trusted"}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EditNftModal;
