import React, { useEffect, useMemo, useState } from 'react';
import * as web3 from '@solana/web3.js';
import { connect } from 'react-redux';
import { PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, AccountLayout } from '@solana/spl-token';


const CONNECTION_URL = 'https://api.devnet.solana.com';
const NFT_MINT_ADDRESSES = [
  'CTBSjb7cKU9m2csTMuxHdXP5xqKMDP1Bt8Fr73Rpyfrm',
  // Add more NFT Metadata addresses here
];

const TempTransferTest = () => {
  const [transferEvents, setTransferEvents] = useState([]);
  const connection = useMemo(() => {
    return new web3.Connection(CONNECTION_URL);
  }, []);
  console.log(connection,"connection in temp test");
  useEffect(() => {
    if (connection) {
        const subscriptionIds = NFT_MINT_ADDRESSES.map((mintAddress) =>
          connection.onProgramAccountChange(
            TOKEN_PROGRAM_ID,
            async (accountInfo, context) => {
                // console.log(accountInfo.accountInfo,"NFT CHANGED TEMP");
              try {
                if (accountInfo.accountInfo.data) {
                    const accountData = AccountLayout.decode(accountInfo.accountInfo.data);
                    // console.log(accountData,"ACCOUNT DATA");
                    console.log(accountData,"Program change");
                    if (accountData.mint.toBase58() === mintAddress) {
                        console.log("TRANSFERRED");
                      const ownerAddress = new web3.PublicKey(accountData.owner).toBase58();
                      setTransferEvents((prevEvents) => [
                        ...prevEvents,
                        `NFT (${mintAddress}) transferred to ${ownerAddress}`,
                      ]);
                    }
                }
              } catch (err) {
                console.error('Failed to decode account data:', err);
              }
            },
            'singleGossip'
          )
        );
  
        return () => {
          subscriptionIds.forEach((subscriptionId) => {
            connection.removeProgramAccountChangeListener(subscriptionId);
          });
        };
      }


  }, [connection]);

//   const parseOwnerAddress = (accountData) => {
//     // Implement account data parsing logic here
//     // ...
//     return ownerAddress;
//   };

  return (
    <div>
      <h2>NFT Transfer Events</h2>
      <ul>
        {transferEvents.map((event, index) => (
          <li key={index}>{event}</li>
        ))}
      </ul>
    </div>
  );
};

export default TempTransferTest;