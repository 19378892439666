import {
    TRUSTED_WALLETS_LOADING,
    UPDATE_TRUSTED_WALLETS_LOADING,
    GET_TRUSTED_WALLETS,
    CHANGE_NETWORK,
  } from "../types";
  import firebase from "../../config/firebase";
  import { v4 as uuidv4 } from "uuid";
  import { toast } from "react-toastify";
  import axios from "axios";
  
  export const trustedWalletsLoading = (val) => async (dispatch) => {
    dispatch({
      type: TRUSTED_WALLETS_LOADING,
      payload: val,
    });
  };
  export const updateLoading = (val) => async (dispatch) => {
    dispatch({
      type: UPDATE_TRUSTED_WALLETS_LOADING,
      payload: val,
    });
  };
  export const changeNetwork = (val) => async (dispatch) => {
    dispatch({
      type: CHANGE_NETWORK,
      payload: val,
    });
  };
  
  export const getTrustedWallets = (payload) => async (dispatch) => {
    dispatch(trustedWalletsLoading(true));
    // let { data } = await TerraRepository.getAllUsers();
    await firebase
      .firestore()
      .collection("Trusted_Wallets")
      .onSnapshot((query) => {
        dispatch(trustedWalletsLoading(false));
        let tempData = [];
        query.forEach((doc) => {
          tempData.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        dispatch({
          type: GET_TRUSTED_WALLETS,
          payload: tempData[0],
        });
  
        dispatch(trustedWalletsLoading(false));
      });
  };
  
export const updateTrustedWallets = (trustedWallets, id, onSuccess = () => {}, onError = () => {}) => async (dispatch) => {
    dispatch(updateLoading(true));
  
    // Check for duplicates
    const uniqueTrustedWallets = [...new Set([...trustedWallets])];
  
    firebase
      .firestore()
      .collection("Trusted_Wallets")
      .doc(id)
      .update({ trustedWallets: uniqueTrustedWallets })
      .then(() => {
        onSuccess();
        dispatch(updateLoading(false));
        dispatch(getTrustedWallets())
        toast.success("Wallets updated");
      })
      .catch((e) => {
        onError(e.message);
        dispatch(updateLoading(false));
      });
  };