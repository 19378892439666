import { Modal, ModalBody } from "reactstrap";
import AdminAddressesList from "./AdminAddressesList";
const AdminAddressesModal = (props) => {
  return (
    <Modal
      size="lg"
      isOpen={props.adminAddressesModal}
      toggle={() => {
        props.setAdminAddressesModal((prevState) => !prevState);
      }}
      // modalClassName={`${props.homepage ? "homepage-modal" : ""}`}
      // size= "lg"
      centered
      //   style={{ width: "100%", maxWidth: "640px" }}
    >
      <ModalBody
      // className="add-nft-model"
      >
        <AdminAddressesList />
      </ModalBody>
    </Modal>
  );
};
export default AdminAddressesModal;
