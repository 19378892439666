/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ethers } from "ethers";
import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { toast } from "react-toastify";
import { addNftCollection, checkNftExists, deployLoader, uploadCollectionMetadeta } from "../../store/actions/nftsAction";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { createUmi, generateSigner, lamports, percentAmount, sol, some, transactionBuilder } from "@metaplex-foundation/umi";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { create, mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { TokenStandard, createNft, mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import { useWallet } from "@solana/wallet-adapter-react";

const acceptedImageTypes = ["png", "jpg", "PNG", "JPG", "jpeg", "JPEG"];
const acceptedFileTypes = ["mp3", "MP3", "m4a"];

const AddNftModal = (props) => {
  const { publicKey, signMessage, disconnecting, disconnect, connected,connect } = useWallet();
  const wallet=useWallet()
  let {network} =useSelector(state => state.wallet);

  // Connect to Solana
  // const url='https://tyo64.nodes.rpcpool.com'
  // const connection = new Connection(url, "confirmed");
  // console.log(connection,"Connection");

  // console.log(connection,"Connection");
  // const umi = createUmi(connection)
  // .use(walletAdapterIdentity(wallet))
  // .use(mplTokenMetadata())
  // .use(mplCandyMachine());

  const dispatch = useDispatch();
  const { deployLoading } = useSelector((state) => state.nft);
  const [nftData, setNftData] = useState({
    collectionName: "",
    collectionShortName: "",
    price: "",
    image: "",
    maxSupply: "",
    maxMintAmountPerTx: 1,
    charityAddress:"",
    charityPercent:0,
    ownerPercent:0,
    mintCount:0,
    charityMintCount:0, 
    candyMachineId:"",
    collectionMintId:"",
    collectionUpdateAuthorityId:"",
    duplicate: false,
    trusted: true,
  });
  const handleNftDataChange = (e) => {
    if (e.target.name === "image") {
      e.preventDefault();
      setNftData({ ...nftData, [e.target.name]: e.target.files[0] });
    } else if (e.target.name === "duplicate" || e.target.name === "trusted") {
      setNftData({ ...nftData, [e.target.name]: e.target.checked });
    } else {
      e.preventDefault();
      setNftData({ ...nftData, [e.target.name]: e.target.value });
    }
  };

  const handleAddNftData = async (e) => {
    e.preventDefault();
    console.log((parseFloat(nftData.charityPercent) + parseFloat(nftData.ownerPercent)));
    if((parseFloat(nftData.charityPercent) + parseFloat(nftData.ownerPercent)) > 100){
      toast.error("Charity and Owner percentage must not exceed 100 percent")
      return
    }
    const solPrice = parseFloat(nftData.price); // Parse the string to a floating-point number
    // Define the conversion factor from SOL to lamports
    const SOL_TO_LAMPORTS = 1000000000; // 1 SOL = 1,000,000,000 lamports   
    // Convert SOL to lamports
    const lamportsPrice = BigInt(Math.round(solPrice * SOL_TO_LAMPORTS));
    console.log(lamportsPrice,"lamports Price",typeof lamportsPrice);
    const collectionUpdateAuthority = generateSigner(props.umi);
    const collectionMint = generateSigner(props.umi);
    const combinedId =
      nftData.collectionName + "-" + nftData.collectionShortName;
    let encodedUrl = encodeURI(combinedId); //using encoded url as firebase storage converts the id into encoded url
    console.log(combinedId,"URLS",encodedUrl);
    dispatch(deployLoader(true));
  try{
    const isDuplicate= await dispatch(checkNftExists(combinedId))
    if(isDuplicate){
      toast.error("Collection with this Id already exists")
      dispatch(deployLoader(false));
      return
    }
    const {mainMetadataUrl,imageUrl} = await toast.promise(
      dispatch(uploadCollectionMetadeta(nftData,combinedId)),
      {
        pending: 'Uploading Collection Image & Metadata',
        success: 'Uploaded Collection Metadata',
        error: 'Error Uploading metadata'
      }
    );

    // const mainMetadataUrl=await dispatch(uploadCollectionMetadeta(nftData,combinedId))
    console.log(mainMetadataUrl,"Main metadata",imageUrl,"percent fix 4");
    let sellerFeeBasisPoints = (nftData.charityAddress ? parseInt(nftData.charityPercent) :0) + parseInt(nftData.ownerPercent);
    let creators = [
      {
        address: props.umi.identity.publicKey,
        verified: true,
        percentageShare: sellerFeeBasisPoints===0?100 :(parseInt(nftData.ownerPercent) * 100 / sellerFeeBasisPoints),
      },
    ];
    
    if (nftData.charityAddress && nftData.charityPercent) {
      let ownerPercentageShare = parseInt(parseInt(nftData.ownerPercent) * 100 / sellerFeeBasisPoints);
      let charityPercentageShare = 100 - ownerPercentageShare;
    
      creators.push({
        address: nftData.charityAddress,
        verified: true,
        percentageShare: charityPercentageShare,
      });
    }
    
  console.log("Creators",creators,sellerFeeBasisPoints);
    toast.info("Allow Transaction for creating collection")
    const createCollection=
    //  toast.promise(
    // Deploying the Contract
    await createNft(props.umi, {
      mint: collectionMint,
      authority: collectionUpdateAuthority,
      name: nftData.collectionName,
      uri: mainMetadataUrl,
      sellerFeeBasisPoints:percentAmount(sellerFeeBasisPoints),
      // name: "My Collection NFT",
      // uri: "https://firebasestorage.googleapis.com/v0/b/nft-project-f9d31.appspot.com/o/test-1.json?alt=media&token=4eb8f945-feb2-4f35-b25b-7e5336791c40",
      isCollection: true,
    }).sendAndConfirm(props.umi)
    


    toast.info("Allow Transaction for creating candy machine")
    const prefixName=`${nftData.collectionShortName} # `
    const prefixUri=`https://firebasestorage.googleapis.com/v0/b/nft-project-f9d31.appspot.com/o/Collections%2F${encodedUrl}%2F`
    const candyMachine = generateSigner(props.umi);
    console.log("TEST BEFORE CREATE INSTRUCTIONS 4");
    const createInstructions = await create(props.umi, {
      candyMachine,
      collectionMint: collectionMint.publicKey,
      collectionUpdateAuthority,
      tokenStandard: TokenStandard.NonFungible,
      sellerFeeBasisPoints: percentAmount(sellerFeeBasisPoints), // No royalty to market place
      itemsAvailable: nftData.maxSupply, // Increase SOL cost per items. Check the cost on Devnet before launch.
      creators: creators,
        configLineSettings: some({
          prefixName: prefixName,
          nameLength: 32-prefixName.length,
          prefixUri: prefixUri,
          uriLength: 200-prefixUri.length,
          isSequential: true,
        }),
        // configLineSettings: some({
        //   prefixName: "",
        //   nameLength: 32,
        //   prefixUri: "https://firebasestorage.googleapis.com/v0/b/nft-project-f9d31.appspot.com/o/Collections%2FTEST%20SOLANA%201%2F",
        //   uriLength: 90,
        //   isSequential: true,
        // }),
      guards: {
        // botTax: some({ lamports: sol(0.00321), lastInstruction: true }),
        mintLimit: some({ id: 1, limit: nftData.maxMintAmountPerTx }),
        solPayment: some({ lamports: lamports(lamportsPrice), destination: props.umi.identity.publicKey }),
        // All other guards are disabled...
      },
    })
    await transactionBuilder().add(createInstructions).sendAndConfirm(props.umi);
    console.log("Accounts")
    console.log(collectionMint.publicKey,"MINT KEY");
    console.log(collectionUpdateAuthority.publicKey,"CM Update authority");
    console.log(candyMachine.publicKey,"CM ID");
    const obj={
      ...nftData,
      imageUrl,
      collectionMintId:collectionMint.publicKey,
      collectionUpdateAuthorityId:collectionUpdateAuthority.publicKey,
      candyMachineId:candyMachine.publicKey,
      network:network
    }
    dispatch(
      addNftCollection(obj, () => {
        clearFields();
        dispatch(deployLoader(false));
        props.setToggleModal((prevState) => !prevState);
        toast.success("Collection Uploaded")
        toast.info("Insert items(NFTs) to initialize minting")
      })
    );
  }catch(error){
    console.log(error.message,error);
    toast(error.message)
    dispatch(deployLoader(false));
  }
    
  };

  const clearFields = () => {
    setNftData({
      collectionName: "",
      collectionShortName: "",
      price: "",
      image: "",
      maxSupply: "",
      maxMintAmountPerTx: 1,
      charityAddress:"",
      charityPercent:0,
      ownerPercent:0,
      mintCount:0,
      charityMintCount:0, 
      candyMachineId:"",
      collectionMintId:"",
      collectionUpdateAuthorityId:"",
      trusted:true
    });
  };

  return (
    <div>
      <Modal
        isOpen={props.nftModalOpen}
        toggle={() => {
          clearFields();
          props.setToggleModal((prevState) => !prevState);
        }}
        className=""
      >
        <ModalHeader
          className="text-site-dark border-0"
          toggle={() => {
            clearFields();
            props.setToggleModal((prevState) => !prevState);
          }}
        >
          Add Nft Collection
        </ModalHeader>
        <ModalBody className="add-nft-model">
          <Form
            onSubmit={(e) => {
              handleAddNftData(e);
            }}
          >
            <Row>
              <Col md="12" className="my-2">
                <Label className="mb-0 my-auto">Collection Name(Max 24 Characters)</Label>
                <Input
                  className="add-nft-input"
                  name="collectionName"
                  required
                  value={nftData.collectionName}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                  maxLength={24}
                />
              </Col>
              <Col md="12" className="my-2">
                <Label className="mb-0 my-auto">Collection Short Name (Max 8 Characters)</Label>
                <Input
                  className="add-nft-input"
                  name="collectionShortName"
                  required
                  value={nftData.collectionShortName}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                  maxLength={10}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Price(Sol)</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="price"
                  required
                  value={nftData.price}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Max Supply</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="maxSupply"
                  required
                  value={nftData.maxSupply}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Max Mint Per User</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="maxMintAmountPerTx"
                  required
                  min={1}
                  value={nftData.maxMintAmountPerTx}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col> 
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Charity Wallet Address</Label>
                <Input
                  className="add-nft-input"
                  name="charityAddress"
                  value={nftData.charityAddress}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col> 
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Charity Percentage(%)</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="charityPercent"
                  min={0}
                  max={100}
                  value={nftData.charityPercent}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col> 
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Owner Percentage(%)</Label>
                <Input
                  className="add-nft-input"
                  type="number"
                  name="ownerPercent"
                  min={0}
                  max={100}
                  value={nftData.ownerPercent}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col> 
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">Image</Label>
                <Input
                  className="add-nft-input"
                  type="file"
                  name="image"
                  required
                  //   value={nftData?.image?.name}
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto mr-4">Duplicate Motto</Label>
                <Input
                  name="duplicate"
                  className="mt-2"
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                  defaultChecked={nftData?.duplicate}
                  type="checkbox"
                />
              </Col>{" "}
              <Col md="12" className="my-2">
                <Label className="mb-0 my-auto mr-4">Collection for trusted wallets only</Label>
                <Input
                  name="trusted"
                  className="mt-2"
                  onChange={(e) => {
                    handleNftDataChange(e);
                  }}
                  defaultChecked={nftData?.trusted}
                  type="checkbox"
                />
              </Col>{" "}
            </Row>
            <div className="text-right my-2">
              <Button
                type="submit"
                className="px-5 review-btn"
                disabled={deployLoading}
              >
                {deployLoading ? <Spinner size="sm" /> : "Add NFT Collection"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNftModal;
