import NftCollections from "../views/NftCollections";
import Home from "../views/Home";
import MintedNfts from "../views/MintedNfts";
import MintApproved from "../views/MintApproved";
import ApprovingPanel from "../views/ApprovingPanel";

let routes = [
  {
    path: "/",
    component: Home,
    layout: "main",
  },
  {
    path: "/nftcollections",
    component: NftCollections,
    layout: "main",
  },
  {
    path: "/motto-approval",
    component: ApprovingPanel,
    layout: "main",
  },
  {
    path: "/minted-nfts/:id",
    component: MintedNfts,
    layout: "main",
  },
  {
    path: "/mint",
    component: MintApproved,
    layout: "main",
  },
];
export default routes;
