import { Button, Container, Row, Spinner } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./MintApproved.css";
import firebase from "../config/firebase";
import { toast } from "react-toastify";
import MintingModal from "../components/NftCollections/MintingModal";
import MintContainer from "../components/MintApproved/MintContainer";
import { useDispatch, useSelector } from "react-redux";
import { getNFTCollectionById } from "../store/actions/nftsAction";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
// import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
// import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { useWallet } from "@solana/wallet-adapter-react";

const MintApproved = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const history = useHistory();
  const [linkCorrect, setLinkCorrect] = useState(false);
  const [motto, setMotto] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const { singleNft } = useSelector((state) => state.nft);
  const redirectToHomepage = () => {
    console.log("Not in the Approved.");
    toast.error("Motto not found");
    toast.warn("Redirecting");
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };
  const checkIfApprovedFromFirebase = async (
    token,
    motto,
    splitCollectionId
  ) => {
    const docRef = firebase.firestore().collection("NftsMottos").doc(token);
    const docSnapshot = await docRef.get();
    
    if (docSnapshot.exists) {
      const firebaseData = docSnapshot.data();
      console.log(firebaseData,"firebaseData");
      if (firebaseData.motto === motto && firebaseData.status === "approved") {
        setLinkCorrect(true);
        const res = await dispatch(
          getNFTCollectionById(splitCollectionId, (result) => {
            if (!result) {
              redirectToHomepage();
            }
          })
        );
        return true;
      } else {
        redirectToHomepage();
        setLinkCorrect(false);
        return false;
      }
    } else {
      redirectToHomepage();
      setLinkCorrect(false);
      return false;
    }
  };
  

  useEffect(() => {
    console.log(params.get("token"));
    var decodedToken = Buffer.from(params.get("token"), "base64").toString();
    var splitToken = decodedToken.split("__")[0];
    var splitMotto = decodedToken.split("__")[1];
    var splitCollectionId = decodedToken.split("__")[2];
    setMotto(splitMotto);
    setCollectionId(splitCollectionId);
    console.log(splitToken + "  " + splitMotto);
    checkIfApprovedFromFirebase(splitToken, splitMotto, splitCollectionId);
  }, []);
  const { publicKey, signMessage, disconnecting, disconnect, connected,connect } = useWallet();
  const wallet=useWallet()
  let {network} =useSelector(state => state.wallet);


    // Connect to Solana
    const url=singleNft?.network==="mainnet"?"https://restless-responsive-emerald.solana-mainnet.quiknode.pro/cdfea05571c73662076548e34eedd775e0988a06/":clusterApiUrl("devnet")
    // const url=clusterApiUrl(singleNft?.network==="mainnet"?"mainnet-beta":"devnet")
    // const url='https://tyo64.nodes.rpcpool.com'
    const connection = new Connection(url, "confirmed");
    console.log(connection,"Connection");
    const umi = createUmi(connection)
    .use(walletAdapterIdentity(wallet))
    .use(mplTokenMetadata())
    .use(mplCandyMachine());


  return (
    <>
      {linkCorrect && singleNft.image ? (
        <div style={{ minHeight: "100vh", backgroundColor: "#173B41" }}>
          {/* {`You can now mint the NFT Motto: ${motto}. CollectionID: ${collectionId}.  \n TODO: Just put the motto in the canvas and give the Mint button how we normally did.`} */}

          <MintContainer umi={umi} mintedNft={singleNft} motto={motto} />
        </div>
      ) : (
        <Container style={{ height: "100vh" }}>
          <Row
            style={{ height: "100%" }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {" "}
            <h4>Verifying Link</h4>{" "}
            <Spinner style={{ width: "2rem", height: "2rem" }} size="lg" />
          </Row>
        </Container>
      )}
    </>
  );
};

export default MintApproved;
