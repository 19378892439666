import {
  DATA_LOADING,
  GET_NFTS,
  MINT_LOADING,
  GET_MINTED_NFTS,
  GET_NFT_COLLECTION_BY_ID,
  DEPLOY_LOADING,
  DELETE_NFT_LOADING,
} from "../types";

const initialState = {
  loading: false,
  deployLoading: false,
  mintLoader: false,
  deleteLoading:false,
  nftCollection: [],
  singleNft: {},
  mintedNfts: [],
};

const nftReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DEPLOY_LOADING:
      return {
        ...state,
        deployLoading: action.payload,
      };
    case MINT_LOADING:
      return {
        ...state,
        mintLoader: action.payload,
      };
      case DELETE_NFT_LOADING:
        return {
          ...state,
          deleteLoading: action.payload,
        };
    case GET_NFTS:
      return {
        ...state,
        nftCollection: action.payload,
      };
    case GET_NFT_COLLECTION_BY_ID:
      return {
        ...state,
        singleNft: action.payload,
      };
    case GET_MINTED_NFTS:
      return {
        ...state,
        mintedNfts: action.payload,
      };

    default:
      return state;
  }
};

export default nftReducer;
