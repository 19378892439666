import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import "./MintedNfts.css";
import NftCollectionInfo from "../components/MintedNfts/NftCollectionInfo";
import {
  getMintedNftsByCollection,
  getNFTCollectionById,
} from "../store/actions/nftsAction";

const MintedNfts = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { mintedNfts, loading, singleNft } = useSelector((state) => state.nft);
  const { isAdmin } = useSelector((state) => state.auth);
  console.log(mintedNfts);

  useEffect(() => {
    if (!isAdmin) {
      history.push("/nftcollections");
    }
    dispatch(getMintedNftsByCollection(params.id));
    dispatch(getNFTCollectionById(params.id));
  }, []);

  console.log(singleNft, mintedNfts, loading, "test");
  return (
    <>
      <Container className="mt-5">
        <NftCollectionInfo singleNft={singleNft} />
        <Row className="align-items-center justify-content-center mb-5">
          <h3>Minted Nfts</h3>{" "}
        </Row>

        {loading ? (
          <>
            <Spinner className="m-5" />
          </>
        ) : (
          <Row className="align-items-center justify-content-center">
            {mintedNfts.map((nft) => (
              <Col className="mr-5 mt-2" xs={5} lg={3}>
                {" "}
                <Card
                  style={{
                    width: "18rem",
                  }}
                >
                  <img alt="No image found" src={nft.image} />
                  <CardBody>
                    <CardTitle tag="h5">{nft.collectionShortName}</CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h4">
                      {nft.id}
                      {/* {getName(nft)} */}
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};
export default MintedNfts;
