import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ethers } from "ethers";

import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { fabric } from "fabric";
import { toast } from "react-toastify";
import {
  BlobUpload,
  checkDuplicate,
  mintLoading,
  mintNftCollection,
} from "../../store/actions/nftsAction";
import LoadingOverlay from "react-loading-overlay";
import { fetchCandyGuard, fetchCandyMachine, mintV2 } from "@metaplex-foundation/mpl-candy-machine";
import { generateSigner, some, transactionBuilder } from "@metaplex-foundation/umi";
import { setComputeUnitLimit } from "@metaplex-foundation/mpl-toolbox";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useHistory } from "react-router-dom";
import { sendMessageToTelegram, sendMessageToTwitter } from "../../store/actions/socialActions";

const MintContainer = (props) => {
  /// TODO: Duplicate Text, motto limit of 20 characters
  const history=useHistory()
  const dispatch = useDispatch();
  const [mottoData, setMottoData] = useState({
    motto: "",
  });
  const [mintedNft, setMintedNft] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [characterLimitError, setCharacterLimitError] = useState("");

  const [functionState, setFunctionState] = useState({
    mintingButton: "Mint",
    mintingButtonDisabled: true,
    mottoSubmit: "Submit",
    mottoSubmitDisabled: false,
  });



  const handleMottoSubmit = async (e) => {
    // e.preventDefault();
    let isDuplicate = await dispatch(
      checkDuplicate(props.mintedNft, props.motto)
    );
    if (!isDuplicate) {
      setFunctionState({
        ...functionState,
        mottoSubmit: "Submitted",
        mottoSubmitDisabled: true,
        mintingButtonDisabled: false,
      });
      var canvas = new fabric.Canvas("minting-canvas");
      document.getElementById("minting-canvas").fabric = canvas;

      var padding =
        parseInt(
          window
            .getComputedStyle(
              document.getElementById("canvas-main-container"),
              null
            )
            .getPropertyValue("padding-right")
            .replace("px", "")
        ) * 2;
      var canvasContainerWidth =
        parseInt(document.getElementById("canvas-main-container").offsetWidth) -
        padding;
      var heightRatio = parseFloat(200 / 471);
      canvas.setWidth(canvasContainerWidth);
      canvas.setHeight(canvasContainerWidth * heightRatio);

      fabric.Image.fromURL(
        props.mintedNft.image,
        function (oImg) {
          // oImg.scaleToWidth(600);
          // oImg.scaleToHeight(280);
          oImg.scaleToWidth(canvasContainerWidth);
          oImg.scaleToHeight(canvasContainerWidth * heightRatio);
          canvas.setBackgroundImage(oImg, null, {
            scaleX: canvas.width / oImg.width,
            scaleY: canvas.height / oImg.height,
          });
          console.log("canvasContainerWidth", canvasContainerWidth);
          var text = new fabric.Textbox(props.motto, {
            textAlign: "center",
            top: (canvasContainerWidth * heightRatio) / 2 - 20,
            // left:
            // width: 600,
            width: canvasContainerWidth,
            lineHeight: canvasContainerWidth * heightRatio,
            fontSize: canvasContainerWidth / 10 - 10,
            // fontSize: 40,
            editable: false,
          });

          canvas.add(text);
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } else {
      toast.error("Duplicate not allowed");
      history.push("/")
      clearFields();
    }
  };

  const clearFields = (e) => {
    setFunctionState({
      mintingButton: "Mint",
      mintingButtonDisabled: false,
    });
  };

  const handleCompleteMinting = async (e) => {
    setFunctionState({
      ...functionState,
      mintingButton: "Minting...",
      mintingButtonDisabled: true,
    });
    var link = document.createElement("a");
    // var canvas = new fabric.Canvas("minting-canvas");
    var canvas = document.getElementById("minting-canvas").fabric;

    var padding =
      parseInt(
        window
          .getComputedStyle(
            document.getElementById("canvas-main-container"),
            null
          )
          .getPropertyValue("padding-right")
          .replace("px", "")
      ) * 2;
    var canvasContainerWidth =
      parseInt(document.getElementById("canvas-main-container").offsetWidth) -
      padding;
    var multiplier = parseFloat(600 / canvasContainerWidth);

    // canvas.renderCanvas();
    var imgData = canvas.toDataURL({ format: "png", multiplier: multiplier });

    var blob = dataURLtoBlob(imgData);
    let recieverAccount;
    let isDuplicate = await dispatch(
      checkDuplicate(props.mintedNft, props.motto)
    );
    console.log("is duplicate",isDuplicate);
    if(!isDuplicate){
      const candyMachine = await fetchCandyMachine(props.umi, props.mintedNft.candyMachineId)
      const candyGuard = await fetchCandyGuard(props.umi, candyMachine.mintAuthority)
      const totalSupplyCollection=Number(candyMachine.data.itemsAvailable)
      console.log(totalSupplyCollection, "total supply before");
      if (totalSupplyCollection === props.mintedNft.maxSupply) {
        toast.error("Max Supply Exceeded");
      } else if (props.mintedNft.paused) {
        toast.error("Contract Paused");
      } else {
        dispatch(mintLoading(true));
        toast.warn("Don't Close the window while minting")
        setShowWarning(true);
        const mintingPromise = new Promise(async (resolve, reject) => {
          try{
            const nftMint =  generateSigner(props.umi)  
          const transaction = await transactionBuilder()
              .add(setComputeUnitLimit(props.umi, { units: 800_000 }))
              .add(
                mintV2(props.umi, {
                  candyMachine: candyMachine.publicKey,
                  candyGuard: candyGuard.publicKey,
                  nftMint,
                  collectionMint:props.mintedNft.collectionMintId,
                  collectionUpdateAuthority:props.mintedNft.collectionUpdateAuthorityId,
                    mintArgs: {
                      mintLimit: some({ id: 1 }),
                      solPayment: some({destination: candyMachine.authority }),
                  },
                })
              )
              // .sendAndConfirm(props.umi);
             
              const { signature } = await transaction.sendAndConfirm(props.umi, {
                confirm: { commitment: "confirmed" },
            });
            resolve();
            // toast("Mint 1 Success")
              const firebaseUpload = new Promise(async (resolve, reject) => {
                try {
                  const blobResponse = await dispatch(
                    BlobUpload({ ...props.mintedNft, totalSupply: Number(candyMachine.itemsRedeemed)+1 }, blob)
                  );
                  console.log(blobResponse, "blobResponse");
                  const tempObj = {
                    ...props.mintedNft,
                    motto: props.motto,
                    totalSupply: parseInt(Number(candyMachine.itemsRedeemed)+1),
                    mintedImage: blobResponse,
                  };
                  dispatch(
                    mintNftCollection(tempObj, () => {
                      // toast.success("Minting Request Sent");
                      resolve();
                      dispatch(sendMessageToTelegram({...props.mintedNft,imageUrl:blobResponse},"mint"));
                      dispatch(sendMessageToTwitter({...props.mintedNft,imageUrl:blobResponse},"mint"));
                      console.log("Minting Completed");
                      toast.success("Mint Success")
                      history.push("/")
                      setShowWarning(false);
                      clearFields();
                    })
                  );
                } catch (e) {
                  console.log(e);
                  reject();
                }
              });
  
              toast.promise(firebaseUpload, {
                pending: "Creating and Uploading Image",
                success: "Image Uploaded",
                error: "Upload Error",
              });
          }
          catch(error){
            toast.error(error.message)
            console.log(error);
            clearFields();
            reject()
          }
          
              
        });
  
        toast.promise(mintingPromise, {
          pending: "Minting",
          // success: "Promise resolved 👌",
          error: "Minting error",
        });
      }
    }else{
      toast.error("Duplicate not allowed")
    }
   
  };

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    if (props.motto && props.mintedNft.image) {
      handleMottoSubmit();
    }
  }, [props.motto, props.mintedNft.image]);
  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      if (showWarning) {
        event.preventDefault();
        event.returnValue = "Are you sure? Minting will not be completed!";
      }
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        if (showWarning) {
          event.preventDefault();
          event.returnValue = "Are you sure? Minting will not be completed!";
        }
      });
    };
  }, [showWarning]);
  return (
    <div>
      <Row className="pt-2 ml-3 d-flex justify-content-between">
        <p className="motto-brand-mint">MOTTO NFT</p>
        <div className="ml-auto"><WalletMultiButton/></div>
      </Row>
      <Row className="pt-5 d-flex align-items-center justify-content-center">
        <h2 className="text-white">MINT YOUR NFT</h2>
      </Row>
      <Container className="">
        <Row>
          <Col
            md={{
              offset: 2,
              size: 8,
            }}
            className="homepage-modal"
          >
            {" "}
            <Row>
              <Col md="12" className="my-2">
                <Label
                  className={`mb-0 my-auto ${true ? "enter-motto-text" : ""}`}
                >
                  Your Motto NFT
                </Label>
              </Col>
            </Row>
            <Row>
              <Col
                md="12"
                className="align-items-center justify-content-center"
                id="canvas-main-container"
              >
                <canvas height={280} width={600} id="minting-canvas"></canvas>
              </Col>
            </Row>
            <div className="text-right my-2">
              <Button
                type="submit"
                className="px-5 review-btn"
                color={`${true ? "info" : "primary"}`}
                disabled={functionState.mintingButtonDisabled}
                onClick={handleCompleteMinting}
              >
                {functionState.mintingButton}
              </Button>
            </div>
          </Col>
          <Col md={2}></Col>
        </Row>{" "}
      </Container>
    </div>
  );
};

export default MintContainer;
