import { Col, Row } from "reactstrap";
import NftLogo from "../../assets/img/Home/NftLogo.png";
import NftTextLogo from "../../assets/img/Home/NftTextLogo.png";
import Fade from "react-reveal";
const SpecsSection = () => {
  return (
    <>
      <Fade left>
        <Row className="specs">
          <Col
            xs={{
              offset: 1,
              size: 5,
            }}
          >
            <Row>
              <p className="specs__header">Specs</p>
            </Row>
            <Row>
              <p className="specs__description__1">
                "TO GET A LITTLE MORE TECHNICAL FOR THE FOLKS WHO ARE INTERESTED, THE NFTs CREATED USING THE METAPLEX CANDY MACHINE ARE SPL-TOKENS ON THE SOLANA BLOCKCHAIN, OR IN OTHER WORDS, SOLANA-BASED NFTs."
                <br />
                <br /> When a user mints a motto-nft a generative algorithm
                randomly produces the ASCII-art representing the motto and emits
                an event that contains it. You can see an example here.
              </p>
            </Row>
          </Col>
          <Col
            xs={{
              size: 5,
            }}
            className="specs-nftLogo"
          >
            <img src={NftLogo} alt="Nft logo"></img>
          </Col>
        </Row>
      </Fade>
      <Fade right>
        <Row className="specs-nftTextLogo__row">
          <Col
            xs={{
              offset: 1,
              size: 5,
            }}
            className="specs-nftTextLogo"
          >
            <img src={NftTextLogo} alt="Nft logo"></img>
          </Col>
          <Col
            xs={{
              size: 5,
            }}
            className="d-flex align-items-center"
          >
            <p className="specs__description__1">
              AFTER THE MOTTO HAS BEEN MINTED AT ANY TIME IT CAN BE SEEN IN THEIR SOLANA WALLET!.
              Calling draw will return the tokens ASCII art, and since the draw
              function is a view call, it doesn't cost gas to call. Thus anyone
              can view the art on chain at any time, for free. Hence, with
              Motto-nfts the token is inseparable from the artwork, and its
              value is linked intrinsically to the ethereum blockchain. So long
              as the ethereum blockchain survives, so too will the Motto-nfts.
            </p>
          </Col>
        </Row>
      </Fade>
    </>
  );
};
export default SpecsSection;
