import { Col, Row } from "reactstrap";
import Fade from "react-reveal";
const SilverMottoSection = () => {
  return (
    <>
      <Fade>
        {" "}
        <Row className="silverMoto__logo">
          <p className="silverMoto__logo__text ">Silver Motto</p>
        </Row>
      </Fade>
      <Fade left>
        <Row>
          {/* <Col xs={1}></Col> */}

          <Col xs={8}>
            <p className="silverMoto__logo__description ">
              WE ARE STARTING THE MOTTO UNIVERSE WITH THE SILVER COLLECTION! ONLY OWNERS OF THE SILVER COLLECTION WILL BE ABLE TO SELECT ALSO A MOTTO FROM THE LATER TO BE RELEASED GOLD AND DIAMOND COLLECTION. DOES NOT MATTER IF YOU WANT TO CONTINUE SHOWING COMMITMENT TO YOUR MOTTO WITH FUTURE COLLECTINOS, YOUR MOTTO WILL BE UNIQUE. A MOTTO CAN CONTAIN UP TO 20 CHARACTERS INCLUDING SPACES. LIVE IS SHORT – AS YOUR MOTTO! SO – WHO IS THE KING? YOU?
            </p>
          </Col>
        </Row>{" "}
      </Fade>
      <Fade right>
        <Row className="">
          <Col
            xs={{
              offset: 3,
              size: 8,
            }}
            className="silverMotto__heading"
          >
            <p className="silverMotto__heading__1">
              Only 1000 silver mottos will be available,
            </p>
            <br></br>
            <p className="silverMotto__heading__2">what are you waiting for?</p>
          </Col>
        </Row>
      </Fade>
    </>
  );
};
export default SilverMottoSection;
