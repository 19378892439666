import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { ethers } from "ethers";
import MintingModal from "../NftCollections/MintingModal";
import { useState } from "react";
import EmailApprovalModal from "./EmailApprovalModal";
const NftMintCard = ({ title, price, imgURL, id,network, nft }) => {
  // console.log('title,price,imgURL', title, price, imgURL);
  const dispatch = useDispatch();
  let [mintingModalOpen, setToggleMintingModal] = useState(false);
  let [mintedNft, setMintedNft] = useState({});
  const handleMintingModal = (nftCollection) => {
    setMintedNft(nftCollection);
    setToggleMintingModal(!mintingModalOpen);
  };
  const weiToEther = (wei) => {
    return ethers.utils.formatEther(wei);
  };

  return (
    <div className="giftCard">
      <div className="d-flex position-absolute giftCard__image">
        <img src={imgURL} alt="" className=" mt-auto w-100 rounded" />
      </div>
      <svg
        className="position-absolute giftCard__color"
        width="184"
        height="278"
        viewBox="0 0 184 278"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M176.839 58.6405L13.3389 0.724967C6.83083 -1.58036 0 3.24672 0 10.1511V225.239C0 229.806 3.0944 233.793 7.51879 234.926L171.019 276.803C177.344 278.423 183.5 273.645 183.5 267.116V68.0666C183.5 63.831 180.832 60.0548 176.839 58.6405Z"
          fill="#FFF0C4"
        />
      </svg>
      {/* <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.48311 0.815291C0.811517 1.99738 -0.826136 6.00569 0.40482 9.82952C1.02947 11.6301 2.04534 13.2485 3.38004 14.5762C5.21101 16.3545 7.22254 17.9194 9.38488 19.2491L9.63044 19.3961C9.86561 19.5369 10.1598 19.5344 10.3926 19.3897L10.6218 19.2473C12.7812 17.9194 14.7927 16.3545 16.6174 14.5824C17.9584 13.2485 18.9743 11.6301 19.5937 9.84515C20.8291 6.00801 19.1847 1.9978 15.512 0.81535L15.2463 0.736231C13.5624 0.273087 11.773 0.501301 10.2645 1.35535L9.99639 1.51429L9.73299 1.35623C8.14194 0.45344 6.2404 0.250031 4.48311 0.815291ZM9.37402 2.85465L9.57075 2.99576C9.83009 3.18149 10.18 3.1778 10.4354 2.98663C11.7654 1.99094 13.4866 1.7012 15.0635 2.20707C17.9091 3.12322 19.2041 6.28134 18.2058 9.38237C17.6643 10.9421 16.768 12.37 15.591 13.5409L15.063 14.0425C13.6404 15.3645 12.1019 16.5613 10.4685 17.616L10.0006 17.9102L10.1434 18.0002C8.07986 16.7312 6.15312 15.2323 4.40519 13.5347C3.23443 12.37 2.33814 10.9421 1.79145 9.36673C0.797453 6.27839 2.08684 3.1225 4.93091 2.20683C6.43269 1.72377 8.06994 1.96327 9.37402 2.85465ZM13.8701 4.48407C13.4854 4.36126 13.0738 4.57336 12.9509 4.95782C12.828 5.34227 13.0403 5.75349 13.425 5.8763C14.1813 6.11768 14.7204 6.79836 14.788 7.60128C14.8218 8.00346 15.1756 8.30208 15.5781 8.26827C15.9806 8.23446 16.2794 7.88103 16.2456 7.47886C16.1287 6.08892 15.1916 4.90584 13.8701 4.48407Z"
          fill="#FFBD00"
        />
      </svg> */}

    <h4 className="fw--700 fs--24 text-white mb-0 mt-auto">{title} {network==="devnet"?<span style={{ color: 'red' }}>(Devnet)</span>:""}</h4>
      <p className="text-white mb-0">{price} Sol</p>
      <Button
        onClick={() => {
          handleMintingModal(nft);
          // mintNFT(nft);
        }}
        className="fw--600 fs--16 text-uppercase position-absolute giftCard__btn"
        // color="primary"
        color="info"
      >
        <h5>MINT</h5>
      </Button>
      {/* <MintingModal
        homepage={true}
        mintingModalOpen={mintingModalOpen}
        handleMintingModal={handleMintingModal}
        mintedNft={mintedNft}
      /> */}
      <EmailApprovalModal
        homepage={true}
        mintingModalOpen={mintingModalOpen}
        handleMintingModal={handleMintingModal}
        mintedNft={mintedNft}
      />
    </div>
  );
};

export default NftMintCard;
