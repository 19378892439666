import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, Row, Spinner } from "reactstrap";

import AddNftModal from "../components/NftCollections/AddNftModal";
import NftCards from "../components/NftCollections/NftCards";
import "./NftCollections.css";
import { toast } from "react-toastify";
import { verifyAdmin } from "../store/actions/authAction";
import ApproversModal from "../components/NftCollections/ApproversModal";
import { useWallet } from "@solana/wallet-adapter-react";
import {  WalletMultiButton, useWalletModal } from "@solana/wallet-adapter-react-ui";

import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, clusterApiUrl } from '@solana/web3.js';
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { addConfigLines, fetchCandyGuard, fetchCandyMachine, mintFromCandyMachineV2, mintV2, mplCandyMachine, updateCandyGuard, updateCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { generateSigner, keypairIdentity, none, percentAmount, sol, some, transactionBuilder } from "@metaplex-foundation/umi";
import { create } from '@metaplex-foundation/mpl-candy-machine'
import {
  createNft,
  fetchDigitalAsset,
  mplTokenMetadata,
  TokenStandard,
} from '@metaplex-foundation/mpl-token-metadata'
import { base58 } from "@metaplex-foundation/umi/serializers";
import { createMintWithAssociatedToken, setComputeUnitLimit } from "@metaplex-foundation/mpl-toolbox";
import TelegramModal from "../components/NftCollections/TelegramModal";
import TwitterModal from "../components/NftCollections/TwitterModal";
import TrustedWalletsModal from "../components/NftCollections/TrustedWalletsModal";
import AdminAddressesModal from "../components/NftCollections/AdminAddressesModal";
import { changeNetwork } from "../store/actions/walletsAction";


const NftCollections = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [nftModalOpen, setToggleModal] = useState(false);
  let [approversModal, setApproversModal] = useState(false);
  let [trustedWalletsModal, setTrustedWalletsModal] = useState(false);
  let [adminAddressesModal, setAdminAddressesModal] = useState(false);
  let [telegramModal, setTelegramModal] = useState(false);
  let [twitterModal, setTwitterModal] = useState(false);
  const { isAdmin } = useSelector((state) => state.auth);
  let {network} =useSelector(state => state.wallet);

  console.log("isAdmin", isAdmin);
  const addNewCollection = () => {
    console.log("Add collections");
    setToggleModal(!nftModalOpen);
  };
  const { publicKey, signMessage, disconnecting, disconnect, connected,connect,sendTransaction } = useWallet();
  const wallet=useWallet()
  const { setVisible } = useWalletModal();
  // Connect to Solana
  // const url=clusterApiUrl(network==="mainnet"?"mainnet-beta":"devnet")
  const url=network==="mainnet"?"https://restless-responsive-emerald.solana-mainnet.quiknode.pro/cdfea05571c73662076548e34eedd775e0988a06/":clusterApiUrl("devnet")
  // const url='https://tyo64.nodes.rpcpool.com'
  const connection = new Connection(url, "confirmed");
  console.log(connection,"Connection NFTC");

  const handleNetworkChange = (event) => {
    const selectedNetwork = event.target.value;
    dispatch(changeNetwork(selectedNetwork))
  };



  
  useEffect(() => {
    console.log(publicKey,connected,"TEST");
    if (publicKey)
      dispatch(
        verifyAdmin(publicKey.toBase58(), () => {
          toast.error("Not Admin");
          history.push("/");
        })
      );
      if(!publicKey && connected){
        toast.error("Disconnected");
          history.push("/");
        }

  }, [publicKey,connected]);
  const umi = createUmi(connection)
  .use(walletAdapterIdentity(wallet))
  .use(mplTokenMetadata())
  .use(mplCandyMachine());
console.log(umi, "UMI");

const transferSolToWallet=async (recipientAddress, amount) => {
  if (!publicKey) {
    console.log('Please connect your wallet first.');
    return;
  }

  const recipient = new PublicKey(recipientAddress);
  const lamports = amount * LAMPORTS_PER_SOL;

  const transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: recipient,
      lamports,
    })
  );

  try {
    const signature = await sendTransaction(transaction, connection);
    console.log(`Transaction signature: ${signature}`);
    toast.success("Transferred")
  } catch (error) {
    console.log('Error sending transaction:', error);
  }
};

  return (
    <>
      {isAdmin ? (
        <>
          <Container className="mt-5">
            <Row className="align-items-center justify-content-center mb-5">
              <h3>Motto NFT Admin Panel</h3>{" "}
            </Row>
            <Row className="align-items-center justify-content-end mb-5">
            <FormGroup>
            <label htmlFor="network-select" className="mr-2">Select Network:</label>
            <Input
              type="select"
              id="network-select"
              value={network}
              onChange={handleNetworkChange}
              style={{ width: "auto" }}
            >
              <option value="mainnet">Mainnet</option>
              <option value="devnet">Devnet</option>
            </Input>
          </FormGroup>


              <WalletMultiButton/>
              <Button
                onClick={() => {
                  setAdminAddressesModal(true);
                }}
                size="lg ml-3"
                color="info"
              >
                Admins
              </Button>{" "}
              <Button
                onClick={() => {
                  setApproversModal(true);
                }}
                size="lg ml-3"
              >
                Approvers
              </Button>{" "}
              <Button
                onClick={() => {
                  setTrustedWalletsModal(true);
                }}
                size="lg ml-3"
              >
                Trusted Wallets
              </Button>{" "}
              <Button style={{backgroundColor:"#229ED9"}} color="primary" size="lg ml-3" onClick={() => {
                  setTelegramModal(true);
                }}>Telegram <i class="bi bi-telegram"></i></Button>
                <Button color="dark" size="lg ml-3" onClick={() => {
                  setTwitterModal(true);
                }}>Twitter <i class="bi bi-twitter-x"></i> </Button>
                 {/* <Button color="light" size="lg ml-3" onClick={() => {
                  transferSolToWallet("42t76MHfgXuLmwHjLqby6wdz6GUc7DxY5WtCUSBqhC27",1.2);
                }}>Transfer Sol  </Button> */}
            </Row>
            <Row className="mt-2 align-items-center justify-content-center">
              <Col
                className="collection-tile"
                md={3}
                onClick={() => addNewCollection()}
              >
                <h5>Add new NFT</h5>
                <i className="bi bi-plus" style={{ fontSize: "45px" }}></i>
              </Col>
              <NftCards umi={umi} connection={connection} />
            </Row>
          </Container>
          <AddNftModal
            nftModalOpen={nftModalOpen}
            setToggleModal={setToggleModal}
            umi={umi}
          />
          <ApproversModal
            approversModal={approversModal}
            setApproversModal={setApproversModal}
          />
          <TrustedWalletsModal
            trustedWalletsModal={trustedWalletsModal}
            setTrustedWalletsModal={setTrustedWalletsModal}
          />
          <TelegramModal
            telegramModal={telegramModal}
            setTelegramModal={setTelegramModal}
          />
           <TwitterModal
            twitterModal={twitterModal}
            setTwitterModal={setTwitterModal}
          />
          <AdminAddressesModal
            adminAddressesModal={adminAddressesModal}
            setAdminAddressesModal={setAdminAddressesModal}
          />
        </>
      ) : (
        <Container style={{ height: "100vh" }}>
          <Row
            style={{ height: "100%" }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {" "}
            {connected && (<>
              <h4>Verifying Admin</h4>{" "}
            <Spinner style={{ width: "2rem", height: "2rem" }} size="lg" />
            </>)}
            {!connected && (<>
              <h4>Select wallet to continue</h4>{" "}
              <WalletMultiButton />
            </>)}
          </Row>
        </Container>
      )}
    </>
  );
};

export default NftCollections;
