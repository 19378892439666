import React from 'react';
// import { FaTelegram } from 'react-icons/fa';  // Assuming you're using react-icons
// import './TelegramBanner.css';  // Import the CSS file

const TelegramBanner = () => {
  return (
    <div className="telegram-banner">
      <i class="bi bi-telegram mr-1"></i>
      <p className="telegram-link">Please follow us on&nbsp;<a className='telegram-header-text' href="https://t.me/MOTTOSOL" target="_blank" rel="noopener noreferrer">Telegram</a></p>
      {/* <a 
        href="https://t.me/MYLINK" 
        target="_blank" 
        rel="noopener noreferrer"
        className="telegram-link"
      >
        Please follow us on 
      </a> */}
    </div>
  );
};

export default TelegramBanner;