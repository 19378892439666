import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import {
  DATA_LOADING,
  GET_NFTS,
  SET_AUTH_LOADING,
  SET_IS_ADMIN,
  ADMIN_ADDRESSES_LOADING,
  UPDATE_ADMIN_ADDRESSES_LOADING,
  GET_ADMIN_ADDRESSES,
} from "../types";


export const setAuthLoader = (val) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING, payload: val });
};
export const isAdmin = (val) => async (dispatch) => {
  dispatch({ type: SET_IS_ADMIN, payload: val });
};
export const adminAddressesLoading = (val) => async (dispatch) => {
  dispatch({
    type: ADMIN_ADDRESSES_LOADING,
    payload: val,
  });
};
export const updateLoading = (val) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADMIN_ADDRESSES_LOADING,
    payload: val,
  });
};
export const verifyAdmin = (address, onFail = () => {}) => async (dispatch) => {
  dispatch(setAuthLoader(true));

  return firebase
    .firestore()
    .collection("Admin_Addresses")
    .doc("adminAddresses")
    .get()
    .then((doc) => {
      if (!doc.exists) {
        onFail();
        dispatch(setAuthLoader(false));
        return false;
      }

      const adminAddresses = doc.data().adminAddresses;
      const isAdminAddress = adminAddresses.includes(address);
      dispatch(isAdmin(isAdminAddress))
      dispatch(setAuthLoader(false));
      if(!isAdminAddress) onFail();
      return isAdminAddress;
    })
    .catch((e) => {
      console.log(e,"Not admin");
      return false;
    });
};






export const getAdminAddresses = (payload) => async (dispatch) => {
  dispatch(adminAddressesLoading(true));
  // let { data } = await TerraRepository.getAllUsers();
  await firebase
    .firestore()
    .collection("Admin_Addresses")
    .onSnapshot((query) => {
      dispatch(adminAddressesLoading(false));
      let tempData = [];
      query.forEach((doc) => {
        tempData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: GET_ADMIN_ADDRESSES,
        payload: tempData[0],
      });

      dispatch(adminAddressesLoading(false));
    });
};

export const updateAdminAddresses = (adminAddresses, id, onSuccess = () => {}, onError = () => {}) => async (dispatch) => {
  dispatch(updateLoading(true));

  // Check for duplicates
  const uniqueAdminAddresses = [...new Set([...adminAddresses])];

  firebase
    .firestore()
    .collection("Admin_Addresses")
    .doc(id)
    .update({ adminAddresses: uniqueAdminAddresses })
    .then(() => {
      onSuccess();
      dispatch(updateLoading(false));
      dispatch(getAdminAddresses())
      toast.success("Admin addresses updated");
    })
    .catch((e) => {
      onError(e.message);
      dispatch(updateLoading(false));
    });
};