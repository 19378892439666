
const initialState = {
  uid: null,
  user: null,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: action.user,
      };
    case "LOGIN_FAIL":
      sessionStorage.clear();
      return {
        ...state,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        uid: "",
        user: null,
      };

    default:
      return { ...state };
  }
}
