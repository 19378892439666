import { Modal, ModalBody } from "reactstrap";
import TrustedWalletsList from "./TrustedWalletsList";
const TrustedWalletsModal = (props) => {
  return (
    <Modal
      size="lg"
      isOpen={props.trustedWalletsModal}
      toggle={() => {
        props.setTrustedWalletsModal((prevState) => !prevState);
      }}
      // modalClassName={`${props.homepage ? "homepage-modal" : ""}`}
      // size= "lg"
      centered
      //   style={{ width: "100%", maxWidth: "640px" }}
    >
      <ModalBody
      // className="add-nft-model"
      >
        <TrustedWalletsList />
      </ModalBody>
    </Modal>
  );
};
export default TrustedWalletsModal;
