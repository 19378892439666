import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletMultiButton,
  useWalletModal,
} from "@solana/wallet-adapter-react-ui";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import React, { useEffect } from "react";
import { verifyApprover } from "../store/actions/approversAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Container, Row, Spinner, Table } from "reactstrap";
import "./ApprovingPanel.css";
import { getMottos, sendApprovalResponse } from "../store/actions/mottosAction";
import LoadingOverlay from "react-loading-overlay";
const ApprovingPanel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { approvers, approversLoading, isApprover } = useSelector(
    (state) => state.approver
  );
  const { mottos, mottosLoading,responseLoading } = useSelector((state) => state.motto);
  const { publicKey, connected } = useWallet();
  const wallet = useWallet();
  const { setVisible } = useWalletModal();

  useEffect(() => {
    if (publicKey)
      dispatch(
        verifyApprover(publicKey.toBase58(), () => {
          toast.error("Not Approver");
          history.push("/");
        })
      );
    if (!publicKey && connected) {
      toast.error("Disconnected");
      history.push("/");
    }
  }, [publicKey, connected]);
  useEffect(() => {
    dispatch(getMottos());
  }, []);
  return (
    <>
      {isApprover ? (
        <>
          <Container className="mt-5">
            <Row className="align-items-center justify-content-between mb-2">
              <h3>Motto Approval Panel</h3> <WalletMultiButton />
            </Row>
            <LoadingOverlay
                active={mottosLoading} // Show loading overlay when mottos are loading
                spinner
                text="Loading mottos..."
              >
            <Row className="align-items-center justify-content-end mb-5">


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Motto</th>
                    <th scope="col">Email</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mottos.map((motto) => (
                      <>
                        <tr>
                          <td>{motto.motto}</td>
                          <td>{motto.email}</td>
                          <td>{motto.collectionId}</td>
                          <td>
                            {motto.status === "pending" ? (
                                <>
                                <Button disabled={responseLoading} onClick={()=>{dispatch(sendApprovalResponse(motto.id,"true"))}} color="success">Accept</Button>{" "}
                                <Button disabled={responseLoading} onClick={()=>{dispatch(sendApprovalResponse(motto.id,"false"))}} color="danger">Reject</Button>
                                </>
                            ) : (
                                <span style={{ color: motto.status === "approved" ? "green" : "red" }}>
                                {motto.status.toUpperCase()}
                                </span>
                            )}
                            </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>

            </Row>
            </LoadingOverlay>
          </Container>
        </>
      ) : (
        <Container style={{ height: "100vh" }}>
          <Row
            style={{ height: "100%" }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {" "}
            {connected && (
              <>
                <h4>Verifying Approver</h4>{" "}
                <Spinner style={{ width: "2rem", height: "2rem" }} size="lg" />
              </>
            )}
            {!connected && (
              <>
                <h4>Select wallet to continue</h4> <WalletMultiButton />
              </>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default ApprovingPanel;
