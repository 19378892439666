import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import {
    GET_TELEGRAM_CONFIG,
    GET_TWITTER_CONFIG,
    TELEGRAM_LOADING,
    TWITTER_LOADING,
  } from "../types";
import axios from "axios";

export const telegramLoading = (val) => async (dispatch) => {
    dispatch({
      type: TELEGRAM_LOADING,
      payload: val,
    });
  };
  export const twitterLoading = (val) => async (dispatch) => {
    dispatch({
      type: TWITTER_LOADING,
      payload: val,
    });
  };
  // Fetch Telegram Config from Firebase
export const getTelegramConfig = () => async (dispatch) => {
    dispatch(telegramLoading(true));
    try {
      const doc = await firebase.firestore().collection("Telegram").doc("config").get();
      if (doc.exists) {
        const data = doc.data();
        dispatch({
        type: GET_TELEGRAM_CONFIG,
        payload: data,
        });
        dispatch(telegramLoading(false));
      } else {
        dispatch(telegramLoading(false));
        toast.error("Document not found")
      }
    } catch (error) {
        dispatch(telegramLoading(false));
        toast.error(error.message)
    }
  };
  
  // Update Telegram Config in Firebase
  export const updateTelegramConfig = (config,onSuccess=()=>{}) => async (dispatch) => {
    try {
        dispatch(telegramLoading(true));
      await firebase.firestore().collection("Telegram").doc("config").update(config);
      dispatch(getTelegramConfig()); // Fetch updated config after update
      dispatch(telegramLoading(false));
      toast.success("Telegram Configuration Updated")
    } catch (error) {
        dispatch(telegramLoading(false));
      console.error("Error updating document: ", error);
      // Handle error if needed
    }
  };

  export const sendMessageToTelegram =
  (payload,messageType, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const obj={imageUrl:payload.imageUrl,messageType}
      console.log(obj, "obj");
      const response = await axios.post(
        "https://us-central1-nft-project-f9d31.cloudfunctions.net/sendMessageToTelegram",
        obj
      );
      console.log(response, "response");
      if (response.status === 200) {
        onSuccess();
      }
    } catch (error) {
      console.log("error",error)
    }
  };

    // Fetch Telegram Config from Firebase
export const getTwitterConfig = () => async (dispatch) => {
  dispatch(telegramLoading(true));
  try {
    const doc = await firebase.firestore().collection("Twitter").doc("config").get();
    if (doc.exists) {
      const data = doc.data();
      dispatch({
      type: GET_TWITTER_CONFIG,
      payload: data,
      });
      dispatch(telegramLoading(false));
    } else {
      dispatch(telegramLoading(false));
      toast.error("Document not found")
    }
  } catch (error) {
      dispatch(telegramLoading(false));
      toast.error(error.message)
  }
};

// Update Twitter Config in Firebase
export const updateTwitterConfig = (config,onSuccess=()=>{}) => async (dispatch) => {
  try {
      dispatch(telegramLoading(true));
    await firebase.firestore().collection("Twitter").doc("config").update(config);
    dispatch(getTwitterConfig()); // Fetch updated config after update
    dispatch(telegramLoading(false));
    toast.success("Twitter Configuration Updated")
  } catch (error) {
      dispatch(telegramLoading(false));
    console.error("Error updating document: ", error);
    // Handle error if needed
  }
};
  export const sendMessageToTwitter =
  (payload,messageType, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const obj={imageUrl:payload.imageUrl,messageType}
      console.log(obj, "obj");
      const response = await axios.post(
        "https://us-central1-nft-project-f9d31.cloudfunctions.net/sendTweetToX",
        obj
      );
      console.log(response, "response");
      if (response.status === 200) {
        onSuccess();
      }
    } catch (error) {
      console.log("error",error)
    }
  };