// Root Reducer

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authUserReducer from "./authUser";
import nftReducer from "./nftReducer";
import approversReducer from "./approversReducer";
import socialReducer from "./socialReducers";
import walletsReducer from "./walletsReducer";
import mottoReducer from "./mottosReducer";
export let rootReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  nft: nftReducer,
  approver: approversReducer,
  social:socialReducer,
  wallet:walletsReducer,
  motto:mottoReducer
});

export default rootReducer;
