import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import React from "react"
import { Button } from "reactstrap";

const ConnectButton = (props) => {
    const { publicKey, signMessage, disconnecting, disconnect, connected } = useWallet();
    if(connected)
    console.log("CONNECTED",publicKey?.toBase58());

    const { setVisible } = useWalletModal();
    const linkWallet = async () => {
      console.log("TES");
          if(!connected)
          setVisible(true)
          else
          disconnect()
      // let creator = accounts[0];
    };
  return (
    <>
    <Button
          onClick={() => {
            linkWallet();
          }}
          className="navbar__button"
        >
          {connected ? (
            <>
              CONNECTED
              <i class="fas fa-check"></i>
            </>
          ) : (
            "CONNECT"
          )}
        </Button>
    </>
  )
};

export default ConnectButton;
