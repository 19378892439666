import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { GET_MOTTOS, MOTTOS_LOADING, RESPONSE_LOADING } from "../types";
import axios from "axios";

export const mottosLoading = (val) => async (dispatch) => {
    dispatch({
      type: MOTTOS_LOADING,
      payload: val,
    });
  };
  export const sendResponseLoading = (val) => async (dispatch) => {
    dispatch({
      type: RESPONSE_LOADING,
      payload: val,
    });
  };
  export const getMottos = (payload) => async (dispatch) => {
    dispatch(mottosLoading(true));
    // let { data } = await TerraRepository.getAllUsers();
    await firebase
      .firestore()
      .collection("NftsMottos")
      .orderBy("createdDate", "desc") 
      .onSnapshot((query) => {
        dispatch(mottosLoading(false));
        let tempData = [];
        query.forEach((doc) => {
          tempData.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        console.log(tempData,"TEMPDATA TEST");
        dispatch({
          type: GET_MOTTOS,
          payload: tempData,
        });
  
        dispatch(mottosLoading(false));
      });
  };
  export const sendApprovalResponse= (id,type) => async (dispatch) => {
    try {
        dispatch(sendResponseLoading(true));
        const response = await axios.get(
          `https://us-central1-nft-project-f9d31.cloudfunctions.net/sendApprovalResponse?approved=${type}&id=${id}`,
        );
        console.log(response, "response");
        if (response.status === 200) {
          dispatch(sendResponseLoading(false));
          toast.success(response.data.message)
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message)
        dispatch(sendResponseLoading(false));
      }
  }