import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { getTelegramConfig, updateTelegramConfig } from "../../store/actions/socialActions";

const TelegramModal = (props) => {
  const dispatch = useDispatch();
  const { telegramConfig, telegramLoading } = useSelector(
    (state) => state.social
  );
  const [telegramData, setTelegramData] = useState({
    chatId: "",
    botId: "",
    collectionAddText:"",
    mintNftText:""
  });
  useEffect(() => {
    dispatch(getTelegramConfig());
  }, []);
  useEffect(() => {
    setTelegramData({
      ...telegramData,
      ...telegramConfig,
    });
  }, [telegramConfig]);
  const handleNftDataChange = (e) => {
    e.preventDefault();
    setTelegramData({ ...telegramData, [e.target.name]: e.target.value });
  };
  const handleSubmitTelegramData=()=>{
        dispatch(updateTelegramConfig(telegramData))
  }
  return (
    <Modal
      size="lg"
      isOpen={props.telegramModal}
      toggle={() => {
        props.setTelegramModal((prevState) => !prevState);
      }}
      centered
    >
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitTelegramData()
          }}
        >
            <Col md="12 mt-3">
              <h3>Telegram Config</h3>
            </Col>
            {telegramLoading && false ? (
              <Spinner></Spinner>
            ) : (
              <Row>
                <Col md="6" className="my-2">
                  <Label className="mb-0 my-auto">Telegram Chat Id</Label>
                  <Input
                    className="add-nft-input"
                    name="chatId"
                    required
                    value={telegramData.chatId}
                    onChange={(e) => {
                      handleNftDataChange(e);
                    }}
                  />
                </Col>
                <Col md="6" className="my-2">
                  <Label className="mb-0 my-auto">Telegram Bot Id</Label>
                  <Input
                    className="add-nft-input"
                    name="botId"
                    required
                    value={telegramData.botId}
                    onChange={(e) => {
                      handleNftDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Collection Added Text</Label>
                  <Input
                    className="add-nft-input"
                    name="collectionAddText"
                    required
                    value={telegramData.collectionAddText}
                    onChange={(e) => {
                      handleNftDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Mint NFT Text</Label>
                  <Input
                    className="add-nft-input"
                    name="mintNftText"
                    required
                    value={telegramData.mintNftText}
                    onChange={(e) => {
                      handleNftDataChange(e);
                    }}
                  />
                </Col>
              </Row>
            )}

          <Button
            // style={{
            //   background: "#FFBD00",
            //   color: "#1C1C1B",
            //   margin: "3px",
            // }}
            disabled={telegramLoading}
            className="ml-3"
            type="submit"
          >
            {telegramLoading ? <Spinner size="sm" /> : "Update"}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default TelegramModal;
