import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

const NftCollectionInfo = ({ singleNft }) => {
  const history = useHistory();
  return (
    <>
      <Row className="mb-3 ">
        <Button
          onClick={() => {
            history.push("/nftcollections");
          }}
        >
          <i className="fas fa-arrow-left"></i> Back
        </Button>
      </Row>
      <Row className="align-items-center justify-content-center mb-3 ">
        <h2>Collection Info</h2>
      </Row>
      <Row className="align-items-center justify-content-center mb-3 ">
        {/* <Col lg={3}></Col> */}
        <Col style={{ cursor: "pointer" }} className="mt-2" md={3}>
          {" "}
          <Card
            style={{
              width: "18rem",
            }}
          >
            <img alt="NFT Collection" src={singleNft?.image} />
            <CardBody>
              <CardTitle tag="h5">
                Short Name:{singleNft?.collectionShortName}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h4">
                Name: {singleNft?.collectionName}
              </CardSubtitle>
              <CardSubtitle className="mb-2 text-muted" tag="h5">
                Price(wei):{singleNft?.price}
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default NftCollectionInfo;
