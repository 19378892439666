import {
    TELEGRAM_LOADING,
    GET_TELEGRAM_CONFIG,
    TWITTER_LOADING,
    GET_TWITTER_CONFIG,
  } from "../types";
  
  const initialState = {
    telegramLoading: false,
    telegramConfig: {},
    twitterLoading: false,
    twitterConfig: {},
  };
  
  const socialReducer = (state = initialState, action) => {
    switch (action.type) {
      case TELEGRAM_LOADING:
        return {
          ...state,
          telegramLoading: action.payload,
        };

      case GET_TELEGRAM_CONFIG:
        return {
          ...state,
          telegramConfig: action.payload,
        };
        case TWITTER_LOADING:
          return {
            ...state,
            twitterLoading: action.payload,
          };
  
        case GET_TWITTER_CONFIG:
          return {
            ...state,
            twitterConfig: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default socialReducer;
  