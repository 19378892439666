import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { getTwitterConfig, updateTwitterConfig } from "../../store/actions/socialActions";

const TwitterModal = (props) => {
  const dispatch = useDispatch();
  const { twitterConfig, twitterLoading } = useSelector(
    (state) => state.social
  );
  const [twitterData, setTwitterData] = useState({
    appKey: "",
    appSecret: "",
    accessToken: "",
    accessSecret: "",
    bearerToken:"",
    collectionAddText:"",
    mintNftText:""
  });
  useEffect(() => {
    dispatch(getTwitterConfig());
  }, []);
  useEffect(() => {
    setTwitterData({
      ...twitterData,
      ...twitterConfig,
    });
  }, [twitterConfig]);
  const handleTwitterDataChange = (e) => {
    e.preventDefault();
    setTwitterData({ ...twitterData, [e.target.name]: e.target.value });
  };
  const handleSubmitTwitterData=()=>{
        dispatch(updateTwitterConfig(twitterData))
  }
  return (
    <Modal
      size="lg"
      isOpen={props.twitterModal}
      toggle={() => {
        props.setTwitterModal((prevState) => !prevState);
      }}
      centered
    >
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitTwitterData()
          }}
        >
            <Col md="12 mt-3">
              <h3>Twitter Config</h3>
            </Col>
            {twitterLoading && false ? (
              <Spinner></Spinner>
            ) : (
              <Row>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">App Key</Label>
                  <Input
                    className="add-nft-input"
                    name="appKey"
                    required
                    value={twitterData.appKey}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">App Secret</Label>
                  <Input
                    className="add-nft-input"
                    name="appSecret"
                    required
                    value={twitterData.appSecret}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Access Token</Label>
                  <Input
                    className="add-nft-input"
                    name="accessToken"
                    required
                    value={twitterData.accessToken}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Access Secret</Label>
                  <Input
                    className="add-nft-input"
                    name="accessSecret"
                    required
                    value={twitterData.accessSecret}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Bearer Token</Label>
                  <Input
                    className="add-nft-input"
                    name="bearerToken"
                    required
                    value={twitterData.bearerToken}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Collection Added Text</Label>
                  <Input
                    className="add-nft-input"
                    name="collectionAddText"
                    required
                    value={twitterData.collectionAddText}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto">Mint NFT Text</Label>
                  <Input
                    className="add-nft-input"
                    name="mintNftText"
                    required
                    value={twitterData.mintNftText}
                    onChange={(e) => {
                      handleTwitterDataChange(e);
                    }}
                  />
                </Col>
              </Row>
            )}

          <Button
            // style={{
            //   background: "#FFBD00",
            //   color: "#1C1C1B",
            //   margin: "3px",
            // }}
            disabled={twitterLoading}
            className="ml-3"
            type="submit"
          >
            {twitterLoading ? <Spinner size="sm" /> : "Update"}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default TwitterModal;
