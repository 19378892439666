import { Modal, ModalBody } from "reactstrap";
import ApproversList from "./ApproversList";
const ApproversModal = (props) => {
  return (
    <Modal
      size="lg"
      isOpen={props.approversModal}
      toggle={() => {
        props.setApproversModal((prevState) => !prevState);
      }}
      // modalClassName={`${props.homepage ? "homepage-modal" : ""}`}
      // size= "lg"
      centered
      //   style={{ width: "100%", maxWidth: "640px" }}
    >
      <ModalBody
      // className="add-nft-model"
      >
        <ApproversList />
      </ModalBody>
    </Modal>
  );
};
export default ApproversModal;
