import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "reactstrap";
import { getNFTCollections } from "../../store/actions/nftsAction";
import NftMintCard from "./NftMintCard";
import "react-multi-carousel/lib/styles.css";
import { useWallet } from "@solana/wallet-adapter-react";
import { getTrustedWallets } from "../../store/actions/walletsAction";
import { verifyAdmin } from "../../store/actions/authAction";
const CollectionsCarousel = (props) => {
  const nftMintCardLeft = useRef();
  const { nftCollection, loading } = useSelector((state) => state.nft);
  const { publicKey, signMessage, disconnecting, disconnect, connected,connect } = useWallet();
  const wallet=useWallet()
  const { trustedWallets, updateTrustedWalletsLoading, trustedWalletsLoading } = useSelector((state) => state.wallet);
  const { isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if(publicKey && connected)
    console.log(publicKey.toBase58(),connected,"Collection Carasoul wallet");
  }, [publicKey,connected]);
  useEffect(() => {
    dispatch(getTrustedWallets());
  }, []);
  const walletAddresses = useMemo(() => {
    if (trustedWallets?.trustedWallets?.length > 0) {
      return trustedWallets?.trustedWallets;
    }
    return [];
  }, [trustedWallets?.trustedWallets]);
  useEffect(() => {
    console.log("KEY CHANGED");
    const timer = setTimeout(() => {
      if (publicKey) {
        console.log("VERIFIED CALLED");
        dispatch(verifyAdmin(publicKey.toBase58()));
      }
    }, 1000); // some delay initially as this is not important for end users
    
    // return () => clearTimeout(timer);
  }, [publicKey, connected]);
  const filteredNFTs = useMemo(() => {
    // if(connected && publicKey)
    // return nftCollection
    //   .filter(nft => nft.isPublished === true) // Filter only published NFTs
    //   .filter(nft => !nft.trusted || walletAddresses.includes(publicKey?.toBase58()))
    //   .filter(nft=> nft.network==="mainnet")
    // else
    // return nftCollection
    // .filter(nft => nft.isPublished === true) // Filter only published NFTs
    // .filter(nft => !nft.trusted)
    // .filter(nft=> nft.network==="mainnet")
    if (connected && publicKey) {
      const filteredNFTsTemp = nftCollection
        .filter((nft) => nft.isPublished === true) // Filter only published NFTs
        .filter((nft) => {
          if (isAdmin) {
            // If isAdmin is true, don't apply the trusted filter
            return true;
          } else {
            // Otherwise, apply the trusted filter
            return !nft.trusted || walletAddresses.includes(publicKey?.toBase58());
          }
        });
  
      if (isAdmin) {
        return filteredNFTsTemp; // Return filteredNFTs without applying the network filter
      } else {
        return filteredNFTsTemp.filter((nft) => nft.network === "mainnet");
      }
    } else {
      return nftCollection
        .filter((nft) => nft.isPublished === true) // Filter only published NFTs
        .filter((nft) => !nft.trusted)
        .filter((nft) => nft.network === "mainnet")
    }
  
  }, [nftCollection, walletAddresses, publicKey,connected,isAdmin]);
  
  const nftMindCardsReponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 991, min: 588 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="pb-5 pt-2" id="giftCards">
        <Row className="gift-card-carousel">
          <Col xs="12">
            
            <Row className="d-flex align-items-center justify-content-center">
              <p className="collections-text-modal">COLLECTIONS</p>
            </Row>
            {loading ? (
              <Row className="d-flex align-items-center justify-content-center">
                <Spinner />
              </Row>
            ) : (
              <Carousel
                className="carousel-height"
                responsive={nftMindCardsReponsive}
                arrows={false}
                customButtonGroup={
                  <CustomButtonGroup nftMintCardLeft={nftMintCardLeft} />
                }
              >
                {filteredNFTs
                .map((nft) => (
                  <NftMintCard
                    title={nft?.collectionName}
                    price={nft?.price}
                    imgURL={nft?.image}
                    id={nft?.id}
                    network={nft?.network}
                    nft={nft}
                  />
                ))}
              </Carousel>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
const CustomButtonGroup = ({
  next,
  previous,
  goToSlide,
  carouselState,
  nftMintCardLeft,
}) => {
  const { currentSlide, slidesToShow, totalItems } = carouselState;
  return (
    <div className="custom-button-group">
      <button
        className="btn-left"
        ref={nftMintCardLeft}
        onClick={() => previous()}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {currentSlide === 0 ? (
            <g opacity="0.3">
              <path
                d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.996 12.5534 27.6251 9.24912 25.188 6.81201C22.7509 4.3749 19.4466 3.00398 16 3ZM21 17H13.4141L15.9492 19.5352C16.0422 19.628 16.116 19.7382 16.1664 19.8596C16.2168 19.9809 16.2428 20.111 16.2428 20.2424C16.2429 20.3738 16.2171 20.5039 16.1668 20.6253C16.1166 20.7467 16.0429 20.8571 15.95 20.95C15.8571 21.0429 15.7468 21.1166 15.6253 21.1668C15.5039 21.2171 15.3738 21.2429 15.2424 21.2428C15.111 21.2428 14.9809 21.2168 14.8596 21.1664C14.7382 21.116 14.628 21.0422 14.5352 20.9492L10.2942 16.7083C10.1067 16.5202 10.0014 16.2655 10.0014 16C10.0014 15.7345 10.1067 15.4798 10.2942 15.2917L14.5352 11.0508C14.7227 10.8636 14.977 10.7585 15.242 10.7587C15.507 10.7588 15.7611 10.8642 15.9485 11.0515C16.1359 11.2389 16.2412 11.493 16.2413 11.758C16.2415 12.023 16.1364 12.2773 15.9492 12.4648L13.4141 15H21C21.2652 15 21.5196 15.1054 21.7071 15.2929C21.8946 15.4804 22 15.7348 22 16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17Z"
                fill="#031D44"
              />
            </g>
          ) : (
            <path
              d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.996 12.5534 27.6251 9.24912 25.188 6.81201C22.7509 4.3749 19.4466 3.00398 16 3ZM21 17H13.4141L15.9492 19.5352C16.0422 19.628 16.116 19.7382 16.1664 19.8596C16.2168 19.9809 16.2428 20.111 16.2428 20.2424C16.2429 20.3738 16.2171 20.5039 16.1668 20.6253C16.1166 20.7467 16.0429 20.8571 15.95 20.95C15.8571 21.0429 15.7468 21.1166 15.6253 21.1668C15.5039 21.2171 15.3738 21.2429 15.2424 21.2428C15.111 21.2428 14.9809 21.2168 14.8596 21.1664C14.7382 21.116 14.628 21.0422 14.5352 20.9492L10.2942 16.7083C10.1067 16.5202 10.0014 16.2655 10.0014 16C10.0014 15.7345 10.1067 15.4798 10.2942 15.2917L14.5352 11.0508C14.7227 10.8636 14.977 10.7585 15.242 10.7587C15.507 10.7588 15.7611 10.8642 15.9485 11.0515C16.1359 11.2389 16.2412 11.493 16.2413 11.758C16.2415 12.023 16.1364 12.2773 15.9492 12.4648L13.4141 15H21C21.2652 15 21.5196 15.1054 21.7071 15.2929C21.8946 15.4804 22 15.7348 22 16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17Z"
              fill="#031D44"
            />
          )}
        </svg>
      </button>
      <button onClick={() => next()} className="btn-right">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {currentSlide + slidesToShow >= totalItems ? (
            <g opacity="0.3">
              <path
                d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.996 12.5534 27.6251 9.24912 25.188 6.81201C22.7509 4.3749 19.4466 3.00398 16 3ZM21.9231 16.3818C21.8729 16.5039 21.7991 16.6149 21.7058 16.7083L17.4648 20.9492C17.2773 21.1364 17.023 21.2415 16.758 21.2413C16.493 21.2412 16.2389 21.1359 16.0515 20.9485C15.8642 20.7611 15.7588 20.507 15.7587 20.242C15.7585 19.977 15.8636 19.7227 16.0508 19.5352L18.5859 17H11C10.7348 17 10.4804 16.8946 10.2929 16.7071C10.1054 16.5196 10 16.2652 10 16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15H18.5859L16.0508 12.4648C15.8636 12.2773 15.7585 12.023 15.7587 11.758C15.7588 11.493 15.8642 11.2389 16.0515 11.0515C16.2389 10.8641 16.493 10.7588 16.758 10.7587C17.023 10.7585 17.2773 10.8636 17.4648 11.0508L21.7058 15.2917C21.8455 15.4318 21.9406 15.61 21.9793 15.8039C22.0179 15.9979 21.9984 16.199 21.9231 16.3818V16.3818Z"
                fill="#031D44"
              />
            </g>
          ) : (
            <path
              d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.996 12.5534 27.6251 9.24912 25.188 6.81201C22.7509 4.3749 19.4466 3.00398 16 3ZM21.9231 16.3818C21.8729 16.5039 21.7991 16.6149 21.7058 16.7083L17.4648 20.9492C17.2773 21.1364 17.023 21.2415 16.758 21.2413C16.493 21.2412 16.2389 21.1359 16.0515 20.9485C15.8642 20.7611 15.7588 20.507 15.7587 20.242C15.7585 19.977 15.8636 19.7227 16.0508 19.5352L18.5859 17H11C10.7348 17 10.4804 16.8946 10.2929 16.7071C10.1054 16.5196 10 16.2652 10 16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15H18.5859L16.0508 12.4648C15.8636 12.2773 15.7585 12.023 15.7587 11.758C15.7588 11.493 15.8642 11.2389 16.0515 11.0515C16.2389 10.8641 16.493 10.7588 16.758 10.7587C17.023 10.7585 17.2773 10.8636 17.4648 11.0508L21.7058 15.2917C21.8455 15.4318 21.9406 15.61 21.9793 15.8039C22.0179 15.9979 21.9984 16.199 21.9231 16.3818V16.3818Z"
              fill="#031D44"
            />
          )}
        </svg>
      </button>
    </div>
  );
};
export default CollectionsCarousel;
