import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Spinner } from "reactstrap";
import {
  getTrustedWallets,
  updateTrustedWallets,
} from "../../store/actions/walletsAction";

const TrustedWalletsList = () => {
  const [walletAddresses, setWalletAddresses] = useState([]); //used to store current and old trustedWallets
  const { trustedWallets, updateTrustedWalletsLoading, trustedWalletsLoading } = useSelector((state) => state.wallet);
  const [newWallet, setNewWallet] = useState("");
  const dispatch = useDispatch();
  const handleAddTrustedWallets = () => {
    setWalletAddresses([...walletAddresses, newWallet]);
    setNewWallet("");
  };
  const handleRemoveTrustedWallet = (ind) => {
    console.log(ind, "INDEXS", walletAddresses);
    let temp = walletAddresses;
    temp.splice(ind, 1);
    setWalletAddresses([...temp]);
  };

  const setValueTrustedWallets = (ind, e) => {
    let temp = [...walletAddresses];
    temp[ind] = e.target.value;
    setWalletAddresses(temp);
  };
  useEffect(() => {
    dispatch(getTrustedWallets());
  }, []);
  useEffect(() => {
    if (trustedWallets?.trustedWallets?.length > 0) {
      setWalletAddresses(trustedWallets?.trustedWallets);
    }
  }, [trustedWallets?.trustedWallets]);
  console.log("walletAddresses", walletAddresses);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(updateTrustedWallets(walletAddresses, trustedWallets.id));
      }}
    >
      <Row>
        <Col md="12 mt-3">
          <h3>Trusted Wallets</h3>
        </Col>
        {trustedWalletsLoading ? (
          <Spinner></Spinner>
        ) : (
          <Col md="10">
            <div className="my-2 ml-3">
              {
                <>
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Button className="addword-icon" onClick={handleAddTrustedWallets}>
                      {"Add new wallet "}
                      <span>
                        <i class="fas fa-plus-circle"></i>
                      </span>{" "}
                    </Button>
                  </Col>
                </>
              }
              {walletAddresses.map((obj, ind) => {
                return (
                  <Col className=" d-flex my-1 pl-0" md="12">
                    <Input
                      className=" w-100 mr-2"
                      required
                      name="walletAddresses"
                      placeholder="Enter wallet address"
                      value={obj}
                      onChange={(e) => {
                        setValueTrustedWallets(ind, e);
                      }}
                    />

                    {
                      <Button
                        className="addword-icon-minus"
                        onClick={() => {
                          handleRemoveTrustedWallet(ind);
                        }}
                      >
                        <i
                          className="fa fa-minus-circle"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    }
                  </Col>
                );
              })}
            </div>
          </Col>
        )}
      </Row>
      <Button
        // style={{
        //   background: "#FFBD00",
        //   color: "#1C1C1B",
        //   margin: "3px",
        // }}
        className="ml-3"
        type="submit"
      >
        {updateTrustedWalletsLoading ? <Spinner size="sm" /> : "Update"}
      </Button>
    </Form>
  );
};

export default TrustedWalletsList;
