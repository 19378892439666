import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ethers } from "ethers";
import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { toast } from "react-toastify";
import { addNftCollection, deployLoader } from "../../store/actions/nftsAction";
import CollectionsCarousel from "./CollectionsCarousel";

const HomeCollectionsModal = (props) => {
  const dispatch = useDispatch();
  const { deployLoading } = useSelector((state) => state.nft);

  return (
    <div>
      <Modal
        centered
        size="lg"
        isOpen={props.nftModalOpen}
        toggle={() => {
          props.setToggleModal((prevState) => !prevState);
        }}
      >
        <ModalBody className="add-nft-model homepage-modal">
          <Row className="d-flex align-items-center justify-content-end mr-3">
            <i
              onClick={() => {
                props.setToggleModal((prevState) => !prevState);
              }}
              className="bi bi-x-lg close-button-modal"
            ></i>
          </Row>
          <CollectionsCarousel />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HomeCollectionsModal;
