import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Spinner,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import greenTick from "../../assets/img/Home/green-tick.png"
import {
  deleteNFTCollection,
  getNFTCollections,
  mintLoading,
  mintNftCollection,
  updateNftCollection,
} from "../../store/actions/nftsAction";
import {
  abi,
  bytecode,
  abi2,
  byteCode2,
  abi3,
  byteCode3,
} from "../../config/contractConfig";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import EditNftModal from "./EditNftModal";
import { useState } from "react";
import MintingModal from "./MintingModal";
import LoadingOverlay from "react-loading-overlay";
import { addConfigLines, fetchCandyGuard, fetchCandyMachine, updateCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { none, some, transactionBuilder } from "@metaplex-foundation/umi";
import { sendMessageToTelegram, sendMessageToTwitter } from "../../store/actions/socialActions";
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
const NftCards = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { nftCollection, loading,deleteLoading } = useSelector((state) => state.nft);
  let [editModalOpen, setToggleModal] = useState(false);
  let [mintingModalOpen, setToggleMintingModal] = useState(false);
  let [editedNft, setEditedNft] = useState({});
  let [mintedNft, setMintedNft] = useState({});
  let [withdrawLoading, setWithdrawLoading] = useState(false);
  let {network} =useSelector(state => state.wallet);

  const { publicKey, signMessage, disconnecting, disconnect, connected,connect,sendTransaction } = useWallet();
  const handleMintingModal = (nft) => {
    setMintedNft(nft);
    setToggleMintingModal(!mintingModalOpen);
  };
  const handleEditModal = (nft) => {
    setEditedNft(nft);
    setToggleModal(!editModalOpen);
  };
  const handleDeleteNft = (nft) => {
    const confirmed = window.confirm(
      `Are you sure you want to Delete Collection ${nft.collectionName}?`
    );
    if(confirmed){
      dispatch(deleteNFTCollection(nft.id))
    }
  };
  const publishCollection=async(nft)=>{
    const candyMachine = await fetchCandyMachine(props.umi, nft.candyMachineId)
    const candyGuard = await fetchCandyGuard(props.umi, candyMachine.mintAuthority)
    if(Number(candyMachine.data.itemsAvailable) !== candyMachine.itemsLoaded){
      toast.error("All items not loaded")
    }else{
      dispatch(updateNftCollection({isPublished:nft.isPublished? false:true},nft.id,() => {
        toast.success(`Collection ${nft.isPublished?"Unpublished":"Published"}`);
              // Call sendToTelegram if NFT is being published
      if (!nft.isPublished) {
        dispatch(sendMessageToTelegram(nft,"collection"));
        dispatch(sendMessageToTwitter(nft,"collection"));
      }
      }))
    }
  } 
  useEffect(() => {
    dispatch(getNFTCollections());
  }, []);
  const mintNFT = async (nftCollection) => {
    console.log("minNFT Called test", nftCollection);
    Web3EthContract.setProvider(window.ethereum);
    let SmartContractObject = new Web3EthContract(
      abi2,
      nftCollection.contractAddress
    );
    let totalSupplyCollection = await SmartContractObject.methods
      .totalSupply()
      .call();
    console.log(totalSupplyCollection, "test total");
    if (totalSupplyCollection === nftCollection.maxSupply) {
      toast.error("Max Supply Exceeded");
    } else if (nftCollection.paused) {
      toast.error("Contract Paused");
    } else {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      let recieverAccount = accounts[0];
      props.handleMintingModal(nftCollection);
      
    }
  };


  const insertItems = async (candyMachineId) => {
    try{
          // setWithdrawLoading(true);
    const candyMachine = await fetchCandyMachine(props.umi, candyMachineId)
    const candyGuard = await fetchCandyGuard(props.umi, candyMachine.mintAuthority)
    console.log(candyMachine,"candyMachine",typeof candyMachine.data.itemsAvailable,Number(candyMachine.data.itemsAvailable));
    console.log(candyGuard,"Candy Guard");

    // if(Number(candyMachine.data.itemsAvailable) !== candyMachine.itemsLoaded){
    //   let items=[]
    //   for(let i=candyMachine.itemsLoaded;i<Number(candyMachine.data.itemsAvailable);i++){
    //     items=[...items,{ name: `${i+1}`, uri: `${i+1}.json?alt=media` },]
    //   }
    //   builder.add(addConfigLines(props.umi, {
    //     candyMachine: candyMachine.publicKey,
    //     index: 0,
    //     configLines: items,
    //   }))
    //   const confirmResult = await builder.sendAndConfirm(props.umi);

    // }
    if (Number(candyMachine.data.itemsAvailable) !== candyMachine.itemsLoaded) {
      let items = [];
      const batchSize = 10;
      const totalItems = Number(candyMachine.data.itemsAvailable) - candyMachine.itemsLoaded;
      const numBatches = Math.ceil(totalItems / batchSize);
      let index = candyMachine.itemsLoaded;
      let itemsInserted = candyMachine.itemsLoaded;

    
      for (let j = 0; j < numBatches; j++) {
        let batch = [];
        const startIdx = candyMachine.itemsLoaded + j * batchSize;
        const endIdx = Math.min(startIdx + batchSize, Number(candyMachine.data.itemsAvailable));
    
        for (let i = startIdx; i < endIdx; i++) {
          batch.push({ name: `${i + 1}`, uri: `${i + 1}.json?alt=media` });
        }
    
        items.push({ index, batch });
        index += batch.length;
        console.log(items,batch,"ITEMS",index);
      }
    
      for (const { index, batch } of items) {
        await addConfigLines(props.umi, {
          candyMachine: candyMachine.publicKey,
          index,
          configLines: batch,
        }).sendAndConfirm(props.umi);
        itemsInserted += batch.length;

        toast(`${itemsInserted}/${Number(candyMachine.data.itemsAvailable)} items inserted`);

        // const confirmResult = await builder.sendAndConfirm(props.umi);
        // console.log(confirmResult,"CONFIRM RESULT");
      }
      console.log("All Items inserted");
      toast.success("All Items inserted")
    }else{
      toast.warn("All items already inserted")
    }
    }catch(error){
    alert(error.message)
    console.log(error)
    }

    // const prefixUri=`https://firebasestorage.googleapis.com/v0/b/nft-project-f9d31.appspot.com/o/Collections%2FRubicon%20Solana-RS%2F`
    // const prefixName="RS # "
    //  const candyUpdate=await updateCandyMachine(props.umi, {
    //   candyMachine: candyMachine.publicKey,
    //   data: {
    //     ...candyMachine.data,
    //     hiddenSettings: none(),
    //     configLineSettings: some({
    //       type: 'configLines',
    //       prefixName: prefixName,
    //       nameLength: 32-prefixName.length,
    //       prefixUri: prefixUri,
    //       uriLength: 200-prefixUri.length,
    //       isSequential: true,
    //     }),
    //   },
    // }).sendAndConfirm(props.umi)
    // console.log("Candy Updated");
  };
  const transferSolToCharity=async(nft)=>{
    
    if(nft.mintCount === nft.charityMintCount){
      toast.warn("Charity Amount already transfered")
      return
    }
    const charityAmount = (nft.price * (nft.charityPercent / 100)) * (nft.mintCount - nft.charityMintCount);
    const lamports = charityAmount * LAMPORTS_PER_SOL;
    const recipient = new PublicKey(nft.charityAddress);
  
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: recipient,
        lamports,
      })
    );
  
    try {
      const signature = await sendTransaction(transaction, props.connection);
      console.log(`Transaction signature: ${signature}`);
      toast.success("Transferred");
      await dispatch(updateNftCollection({charityMintCount:nft.mintCount},nft.id,()=>{
        toast.success("Charity Amount Transferred for the minted nfts")
      }))
      nft.charityMintCount = nft.mintCount; // Update the charityMintCount
    } catch (error) {
      console.log('Error sending transaction:', error);
    }
  }
  return (
    <>
      {loading ? (
        <>
          <Spinner className="m-5" />
        </>
      ) : (
        <>
          {/* <Button
            onClick={() => {
              unpauseContract();
            }}
          >
            TEST
          </Button> */}

          {nftCollection.filter(nft => nft.network === network).map((nft) => (
            <Col className="mr-5 mt-2" sm={6} md={4} lg={3}>
              {" "}
              <Card
                style={{
                  width: "18rem",
                }}
              >
                <img
                  onClick={() => {
                    history.push(`/minted-nfts/${nft.id}`);
                  }}
                  alt="NFT Collection"
                  src={nft.image}
                  style={{ cursor: "pointer" }}
                  width={286}
                  height={150}
                />
                <CardBody>
                  <Row className="d-flex justify-content-end">
                    <Button disabled={deleteLoading} onClick={() => {
                      handleDeleteNft(nft);
                    }} color="danger mr-1">
                      <i className="fas fa-trash"></i>
                    </Button>
                    <Button
                      onClick={() => {
                        handleEditModal(nft);
                      }}
                      color="success"
                    >
                      {" "}
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </Row>
                  <CardTitle tag="h5">
                    {nft.collectionShortName}{" "}
                    {nft.isPublished && (
                      <>
                        <img
                          height={24}
                          width={24}
                          className="ml-1 mb-1"
                          src={greenTick}
                          alt="green tick"
                          id="publishedTooltip"
                        ></img>
                        <UncontrolledTooltip
                          placement="right"
                          target="publishedTooltip"
                        >
                          Collection is published
                        </UncontrolledTooltip>
                      </>
                    )}
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h4">
                    {nft.collectionName}
                  </CardSubtitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Price(Sol):{nft.price}
                  </CardSubtitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Max Supply:{nft.maxSupply}
                  </CardSubtitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Charity Royalty Percentage:{nft?.charityPercent}
                  </CardSubtitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Owner Royalty Percentage :{nft?.ownerPercent}
                  </CardSubtitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Max Mints per Transaction:{nft.maxMintAmountPerTx}
                  </CardSubtitle>
                  <Row className="justify-content-between">
                    <Button
                      onClick={() => {
                        handleMintingModal(nft);
                        // mintNFT(nft);
                      }}
                    >
                      Mint
                    </Button>
                    <Button
                      style={{
                        backgroundColor: nft.isPublished
                          ? "#6c757d"
                          : "#28a745",
                      }}
                      onClick={() => {
                        publishCollection(nft);
                        // mintNFT(nft);
                      }}
                    >
                      {nft.isPublished ? "Unpublish" : "Publish"}
                    </Button>
                    <Button
                      variant="primary"
                      style={{ backgroundColor: "#0d6efd" }}
                      onClick={() => {
                        insertItems(nft.candyMachineId);
                      }}
                      disabled={withdrawLoading}
                    >
                      {withdrawLoading ? <Spinner size="sm" /> : "Insert Items"}
                    </Button>
                   {(nft.charityAddress && nft.mintCount) ? 
                   (<Button className="mt-2" color="info" onClick={() => {
                        transferSolToCharity(nft);
                      }}>
                      Transfer Minted Sol to Charity
                    </Button>) :""}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      )}
      <EditNftModal
        nftModalOpen={editModalOpen}
        setToggleModal={setToggleModal}
        editedNft={editedNft}
      />
      <MintingModal
        mintingModalOpen={mintingModalOpen}
        handleMintingModal={handleMintingModal}
        mintedNft={mintedNft}
        umi={props.umi}
      />
      {/* <div
        onClick={() => {
          setToggleMintingModal(!mintingModalOpen);
        }}
      >
        MintingModal
      </div> */}
    </>
  );
};
export default NftCards;
